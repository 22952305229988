import React from 'react'
import './feeling.css'
import FeelingPicture from "../../assets/bigger-feeling-image.png"
import FeelingTextPicture from "../../assets/feeling-text-picture-bigger.png"
const Feeling = () => {
    return (
        <><div className='desktop_view feeling-container'>
            <img src={FeelingTextPicture} className='feeling-text-picture' />
            <div className='feeling-details-inner-container'>
                <span className='feeling-title'>Maybe you feel</span>
                <span className='feeling-title-details'>Stressed, Overwhelmed
                    or Lacking Direction And Purpose</span>
                <p className='feeling-title-details-desire'>Perhaps you desire to do more and be more, to break free from limitations. If you're uncertain about where to begin, we can help you navigate the journey ahead.</p>
                <button className="me-4 secondary_btn my-4 LINK capitalize w-50">FIND MORE</button>
                <p className='feeling-title-details-journey'>And find out where you’re at in your journey, and how Minded can help you get to the next stage.</p>
            </div>
            <img src={FeelingPicture} className='feeling-picture' />
        </div>
        <div className='tablet_view mobile_feel_container'>
          <span className='feeling_title_mobile'>Maybe you feel</span>
          <div className="feeling-text-container">
          <span className='feeling_title_details_mobile'>Stressed,</span>
          <span className='feeling_title_details_mobile'>Overwhelmed or</span>
          <span className='feeling_title_details_mobile'>Lacking Direction And</span>
          <span className='feeling_title_details_mobile'>Purpose</span>
          </div>
          <p className='feeling-title-details-desire_mobile'>Perhaps you desire to do more and be more, to break free from limitations. If you're uncertain about where to begin, we can help you navigate the journey ahead.</p>
          <button className="secondary_btn secondary_btn_mobile LINK capitalize" 
            >
            SUBSCRIBE TODAY
          </button>
          <p className='feeling_title_details_journey_mobile'>And find out where you’re at in your journey, and how Minded can help you get to the next stage.</p>
        </div>
        </>
    )
}

export default Feeling