import { useEffect, useState } from "react"
import { baseURL } from "../actions/Constants"


export const useCategories = ()=>{
    const [categoriesList, setCategoriesList]  = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setError] = useState("")
    const [isAddedSuccesffully, setIsAddedSuccessfully] = useState(false)
    const [isUpdatedSuccessfully, setIsUpdatedSuccessfully] = useState(false)
    

    const getAllCategories = async () => {
    const URL  = `${baseURL}/minded-services/categories`
    setIsLoading(true)

        try {
            const response = await fetch(URL, { method: 'GET' });
            if (!response.ok) {
                setIsLoading(false)
              return;
            }
            const data = await response.json(); 
            
            const mappedData = data.map(category => {
                return {
                    dbId: category?._id,
                    id: category?.categoryId,
                    name: category?.name
                }
            }).sort((a, b) => a.name.localeCompare(b.name));

            setCategoriesList(mappedData); 
            setIsLoading(false)
          } catch (error) { 
            setIsLoading(false)
            setError("Error occured please try again later")
            return;
          }
    }

    const addCategory =  async (categoryName) =>{
        const URL  = `${baseURL}/minded-services/category`

        const headerOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({name:categoryName})
        };

        try {
          const response = await fetch(URL, headerOptions);
          const textData = await response.text();
    
          if (response.status === 200 || response.status === 201) {
            setIsAddedSuccessfully(true)
            getAllCategories()
          } else {
            setError(textData);
          }
        } catch (error) {
          setError("Error occured please try again later")
        }finally{
          setTimeout(()=>{
            setError("")
            setIsAddedSuccessfully(false)
        },[2000])
        }

    }

    const updateCategory = (category, categoryName) =>{

        const URL  = `${baseURL}/minded-services/category/${category?.id}`

        const payload = {
          _id: category?.dbId,
          name: categoryName,
          categoryId:category?.id
        }

        fetch(URL, {
            method: 'PUT',
            body: JSON.stringify(payload), 
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((data)=>{
            setIsUpdatedSuccessfully(true)
            getAllCategories()
          }).catch((error)=>{
            setError("Error occured please try again later")
          }).finally(()=>{
            setTimeout(()=>{
                setError("")
                setIsUpdatedSuccessfully(false)
            },[2000])
          })
    }



    return{
        categoriesList,
        getAllCategories,
        addCategory,
        isLoading,
        setError,
        isError,
        isAddedSuccesffully,
        setCategoriesList,
        updateCategory,
        isUpdatedSuccessfully
    }

}