import Slider from "react-slick";
import ProgressBar from "../../components/progress-bar/ProgressBar";
import { useState } from "react";
import "./feature-details.css"
const data = [
	{
		title: "CONNECTION",
		description: "The Minded Club is more than just networking; it's about forging meaningful relationships that transcend surface interactions. Our platform provides a space where individuals can connect on a deep level, fostering a sense of belonging and shared purpose.",
		bgClass: "primary_10_bg",
		textClass: "white_text"
	},
	{
		title: "GUIDANCE",
		description: "We understand that the journey of self-discovery and personal growth can be complex, and having the right guidance can make all the difference. Our community is built on the principles of mentorship, peer support, and shared wisdom.",
		bgClass: "accent_100_bg",
		textClass: "color-primary-50"
	},
	{
		title: "COMMUNITY",
		description: "Our community is a diverse blend of backgrounds, experiences, and perspectives, weaving together shared stories that encourage individuals to embrace authenticity and contribute to the collective journey of growth. We are here to support each other.",
		bgClass: "secondary_100_bg",
		textClass: "white_text"
	},
	{
		title: "GROWTH",
		description: "We provide a comprehensive range of resources, tools, and give access to experts in all fields of personal growth to help our users unlock their full potential and live a more fulfilling life.",
		bgClass: "secondary_90_bg",
		textClass: "color-primary-50"
	}
];

const FeatureDetailsMobileVersion = () => {

	const [activeSlider, setActiveSlider] = useState(0)

	const settings = {
		afterChange: current => setActiveSlider(current)
	}

	return (
		<div className="py-3">
			<Slider {...settings} slidesToShow={1} infinite={false}>
				{data.map((item, index) => (
					<div key={index} className={`w-100 text-view-mobile ${item.bgClass} feature-details-container`}>
						<div>
							<div className={`subtitle-B fw-bold py-3 ${item.textClass}`}>{item.title}</div>
							<div className={`body-R py-2 ${item.textClass}`} style={{
								fontSize: '20px',
							}}>{item.description}</div>
						</div>
					</div>
				))}
			</Slider>
			<div className="py-3">
				<ProgressBar  progress={(activeSlider / (data.length - 1)) * 100}/>
			</div>
		</div>
	)
}

export default FeatureDetailsMobileVersion;