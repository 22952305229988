import { useState } from "react";
import "./growth-journey.css";
import YearlyOff from "../../assets/yearly-off.svg";
import YearlyOffOnTop from "../../assets/yearly-off-on-top.svg";
import React from "react";
import {
  growthJourneyItemsMonthly,
  growthJourneyItemsYearly,
} from "../../actions/Constants";
import GrowthJourneyItem from "../../components/growther-journey/GrowthJourneyItem";
import Slider from "react-slick";
import { useRef } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import Arrows from "../../components/Arrows/Arrows";

const GrowthJourney = ({
  showTitle = true,
  showSaveMore = true,
  showSaveMoreIconOnTop=false,
  onHandlePlanDetailsChange,
  userDetailsPlanId,
  onClickHandler,
  isLoggedIn
}) => {
  let sliderRef = useRef(null);
  const [selectedPeriod, setSelectedPeriod] = useState("MONTHLY");
  const itemRefs = useRef([]);
	const [activeSlider, setActiveSlider] = useState(0)
  const [maxHeight, setMaxHeight] = useState('650px');
  const isSmallDevice = useMediaQuery('(max-width:768px)')  
	const settings = {
		afterChange: current => setActiveSlider(current)
	}



  const items =
    selectedPeriod === "YEARLY"
      ? growthJourneyItemsYearly
      : growthJourneyItemsMonthly;

      useEffect(() => {
        if (itemRefs.current.length > 0) {
          // console.log('itemRefs.current[activeSlider]', itemRefs.current[activeSlider].clientHeight);
          if(itemRefs.current[activeSlider]?.clientHeight !==0 ){
            setMaxHeight(itemRefs.current[activeSlider]?.clientHeight + "px" );

          }
        }
      }, [activeSlider, selectedPeriod]);      

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  return (
    <div className="growth-journey-container">
      {showTitle && (
        <h2 className="growth-journey-title">
          Start Your Growth Journey
        </h2>
      )}
      <div className="yearly-off-on-top-container">
        {showSaveMoreIconOnTop && (
          <img className="yearly-off" src={YearlyOffOnTop} alt="arrow" />
        )}
      </div>
      <div className="button-container">
        <button
          className={`button-period ${
            selectedPeriod === "MONTHLY" ? " button-period-select" : ""
          }`}
          style={{zIndex:9}}
          onClick={() => {
            setSelectedPeriod("MONTHLY");
          }}
        >
          Monthly
        </button>
        {!showSaveMoreIconOnTop && (
          <img className="yearly-off" src={YearlyOff} alt="arrow" />
        )}
        {showSaveMore && <span className="save-more">Save 44%</span>}
        <button
          className={`button-period button-yearly${
            selectedPeriod === "YEARLY" ? " button-period-select" : ""
          }`}
          style={{ borderTopLeftRadius: "0px", borderBottomLeftRadius:'0px'}}
          onClick={() => {
            setSelectedPeriod("YEARLY");
          }}
        >
          Yearly
        </button>
      </div>
      {showSaveMoreIconOnTop && (
        <div className="save-more-description">
          <span>
            Choose a yearly pricing plan and get additional discounts! Save 44%!
          </span>
        </div>
      )}
     {!isSmallDevice && <div className="growth-journey-items-container">
        {items.map((growth, index) => {
          return (
              <GrowthJourneyItem
              onHandlePlanDetailsChange={onHandlePlanDetailsChange}
              growth={growth}
              isSelected={growth?.subscriptionId === userDetailsPlanId}
              onClickHandler={onClickHandler}
              isLoggedIn={isLoggedIn}
            />
          );
        })}
      </div>}
     {isSmallDevice && <div className="growth-journey-items-container" style={{
        maxHeight:maxHeight
      }}>
        <Arrows showArrows={items?.length > 1} showLeftArrow={activeSlider > 0} showRightArrow ={activeSlider !== items.length -1} nextHandler={next} previousHandler={previous}/>
        <Slider
        ref={slider => {
          sliderRef = slider;
        }} infinite={false} {...settings}>        
          {items.map((growth, index) => {
          return (
            <div ref={(el) => (itemRefs.current[index] = el)} key={growth.subscriptionId}>
            <GrowthJourneyItem
              onHandlePlanDetailsChange={onHandlePlanDetailsChange}
              growth={growth}
              isSelected={growth?.subscriptionId === userDetailsPlanId}
              onClickHandler={onClickHandler}
              isLoggedIn={isLoggedIn}
            />
            </div>
          );
        })}
        </Slider>
        </div>
        }
    </div>
  );
};

export default GrowthJourney;
