import {useState, useEffect, useRef} from 'react';
import AddLocationView from '../../../components/addLocationView/AddLocationView';
import Input from '../../../components/input/Input';
import MultiSelectDropdown from '../../../components/multi-select-dropdown/MultiSelectDropdown';
import {Mode_Array} from '../../../utils/DefaultInitialValues';
import {LangList} from '../../../utils/DefaultStates';
import { useCategories } from '../../../hooks/useCategories';
import Loading from '../../../components/loading/Loading';

const CatalystProfileDetails = (props) => {
  const dialogRef = useRef();
  const {getAllCategories, categoriesList, isLoading} = useCategories()

	const {
    userErrors = {},
    userDetails = {}, 
    addLocation, 
    onHandleChange, 
    boardingErrors = {}, 
    onDropdownChange, 
    onLocationChange,
    onHandleCatalystDetailsChange, 
    handleLocationChange,
    onHandleProfileChange,
    onHandleAcountChange,
    onHandleProfileCompanyChange,
    onHandleProfileSocialChange
  } = props;

  const {
    catalystDetails = {},
    profileDescription = {},
    accountDetails = {}
  } = userDetails || {};

  const {
    languages = [],
    mode = "",
    locations = [],
    serviceCategory = []
  } = catalystDetails || {};

  const {
    taxNumber = ""
  } = accountDetails || {};

  const {
    aboutMe = "",
    publicName = "",
    companyDetails = {
      companyName: "",
      url: ""
    },
    socialMediaHandles = [
      {
        name:"facebook",
        link: ""
      },
      {
        name :"instagram",
        link:""
      }
    ]
  } = profileDescription || {};

  const {
    taxNumberError = false,
    expertiseError = false,
    languageError = false,
    locationError = false
  } = userErrors || {};
  

  useEffect(() => {
    if (dialogRef.current) {
      dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  
    getAllCategories();
  }, []);

	return (
		<>
    {isLoading && <Loading/>}
      <div className="container dialog_padding" ref={dialogRef}>
      	<div className="row">
      		<div className="col-lg-6 m-auto">
            
            <div className="w-100 card-view bg-white flex_container_40 bg-white padding_70 my-3 border_50 color-primary-50">
              <h5 className="heading-5 color-primary-50">2.1 Service/Expertise Info</h5>
            
              <div className="mb-2 w-100">
                <label for="serviceCategory" className="heading-6 color-primary-50 py-2">Expertise *</label>
                
                <MultiSelectDropdown 
                  name="serviceCategory" 
                  id="serviceCategory" 
                  selectedValue = {serviceCategory} 
                  onDropdownChange = {onDropdownChange} 
                  options = {categoriesList} 
                />

                {expertiseError && <div className="text-danger pt-1">! Expertise is a required field</div>}
              </div>

              <div className="mb-4 LINK color-secondary-100">Not able to find your service? 
                <a className="ax-button fw-bold LINK color-secondary-100" href="mailto:hello@theminded.club"> Request for new</a>
              </div>

              <div className="mb-2 w-100">
                <label for="services" className="heading-6 color-primary-50 py-2">Language of service *</label>
                <MultiSelectDropdown 
                  name="languages" 
                  id="languages" 
                  selectedValue = {languages} 
                  onDropdownChange = {onDropdownChange} 
                  options = {LangList}
                  viewLabel = "value"
                />
                {languageError && <div className="text-danger">! Language is a required field</div>}
              </div>

              <div className="mb-2 w-100">
                <label for="services" className="heading-6 color-primary-50">Mode of service *</label>
                <div className="my-2">
                  {Mode_Array.map((item, index) => 
                    <>
                      <input 
                        type="radio"
                        name="mode" 
                        id={item.value} 
                        className="mode px-2" 
                        value = {item.value}
                        checked= {item.value === mode}
                        onChange={(e) => onHandleCatalystDetailsChange(e)} 
                      />
                      <label for={item.value} className="pe-3">
                        <div className="">
                          <div className="d-flex align-items-center">
                            <span className="body-R px-2">{item?.label}</span>
                          </div>
                        </div>
                      </label>
                    </>
                  )}
                </div>
              </div>

              <div className="mb-2 w-100">
                <label for="location" className="heading-6 color-primary-50">Location *</label>
                <AddLocationView list = {locations} handleLocationChange = {handleLocationChange} />
                {locationError && <div className="text-danger">! Location is a required field</div>} 
              </div>

              <div className="w-100">
                <button type="button" className="capitalize tertiary_btn w-100" onClick={addLocation}>Add More Locations</button>
              </div>       
            </div>

            <div className = "w-100 card-view bg-white flex_container_40 bg-white padding_70 my-3 border_50 color-primary-50">
              <h5 className="heading-5 color-primary-50">2.2 Billing Information</h5>
              <div className="w-100">
                <Input 
                  id="taxNumber"
                  placeHolder = "xxxx-xxxx-xxxx-xxxx" 
                  label = "Tax number"
                  name="taxNumber" 
                  classVal="w-100"
                  value={taxNumber}
                  isRequired = {true}
                  onInputChange = {e => onHandleAcountChange(e)}
                /> 
              </div>
              {taxNumberError && <div className="text-danger">! Tax Number is a required field</div>}

              <div className="mb-2 w-100">
                <Input
                  name = "companyName"
                  label = "Company name (if any)"
                  value = {companyDetails?.companyName}
                  onInputChange = {(e) => onHandleProfileCompanyChange(e)} 
                />
              </div>
            </div>
             
            <div className = "w-100 card-view bg-white flex_container_40 bg-white padding_70 my-3 border_50 color-primary-50">
              <h5 className="heading-5 color-primary-50">2.3 Others</h5>

              <div className="mb-2 w-100">
                <Input
                  name = "instralink"
                  label = "Instagram  link (if any)"
                  value = {socialMediaHandles[1]?.link}
                  onInputChange ={(e) => onHandleProfileSocialChange(e, 1)} 
                />
              </div>
            </div>
      		</div>
      	</div>
      </div>
    </>
	)
}

export default CatalystProfileDetails;