import {useState, useEffect} from 'react';
import Multiselect from 'multiselect-react-dropdown';
import './multi-select.css';

const MultiSelectDropdown = (props) => {

	const [list, setList] = useState([]);

	const {
		id = "", 
		name = "", 
		options = [], 
		onDropdownChange, 
		selectedValue = [],
		viewLabel = "name"
	} = props;

	const onSelect = (list, item) => {
		onDropdownChange(name, list);
	}

	const onRemove = (list, item) => {
		onDropdownChange(name, list);
	}

	useEffect(() => {

		const latestList = options.map((item, index) => {
			const obj = {};
			obj.name = item.name;
			obj.id = item.id;
			obj.value = item.name;
			return obj;
		});

		setList(latestList);
	}, [options]);

	return (
		<Multiselect
			id={id}
			name={name}
			options={list} 
			selectedValues={selectedValue} // Preselected value to persist in dropdown
			onSelect={onSelect} // Function will trigger on select event
			onRemove={onRemove} // Function will trigger on remove event
			displayValue={viewLabel} // Property name to display in the dropdown options
		/>
	)
}

export default MultiSelectDropdown;