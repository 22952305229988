import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import { Breadcrumb } from "rsuite";

import AddServiceDialog from '../../../admin/createService/serviceDialog';
import CustomizedSnackbars from  '../../../../components/CustomizedSnackbars';
import ClassListView from '../../../../components/classListView/ClassListView';

import DeleteDialogView from '../../../../components/delete-service/DeleteDialogView';
import FilterView from '../../../../components/filters/FilterView';
import HelmetDetails from "../../../../components/HelmetDetails";
import ListView from '../ListView';
import Loading from '../../../../components/loading/Loading';
import MyOrder from './MyOrder';
import Noresult from '../../../../components/no-result/Noresult';
import SelectwithoutLabel from '../../../../components/selectwithoutLabel/SelectWithoutLabel';

import TabView from '../../../../components/tabView/TabView';

import OnBoardingDialog from '../../../../pages/admin/onBoardingAdmin/OnBoardingDialog';
import {
	InitialServiceDetails,
	Catalyst_Details_Service_Tab_List, 
	Service_Status_Array, headerOption
} from '../../../../utils/DefaultInitialValues';

import {
	getFormattedDate,
	generateEventsDropdownList, 
	getCatalystDetailsServiceTypeFromActiveTab,
	getFormattedCurrentMonthFirstDate,
	getFormattedCurrentMonthTodayDate,
	getCurrentMonthText, 
	getCurrentMonthFirstDayEpoc,
	SIBSStatusText
} from '../../../../utils/util';

import ParticipantDialog from './ParticipantDialog';
import {getServices} from '../../../../actions/services.actions';
import {saveAdmin} from '../../../../actions/admin.actions';
import {baseURL} from '../../../../actions/Constants';

import avatar from '../../../../assets/avatar.png';
import right_arrow from '../../../../assets/thin arrow - right.png';
import Refresh from '../../../../assets/Refresh.png';
import '../dashboard.css';

const CatalystDashboard = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const location = useLocation();
	const [crumb, setCrumb] = useState([]);

	const getServicesAction = getServices;
	const saveAdminAction = saveAdmin;

	const currentMonth = getCurrentMonthText();

    const [user, setUser] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    
    const [isShowDialog, setShowDialog] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [serviceStatus, setServiceStatus] = useState("active");

	const [allServices, setAllServices] = useState([]);
	const [filterServices, setFilterServices] = useState([]);

	const [allSchedules, setAllSchedules] = useState([]);
	const [filterSchedules, setFilterSchedules] = useState([]);

	const [catalystRevenuePrice, setCatalystRevenuePrice] = useState("--");
	const [totalSoldTicketCount, setTotalTicketSoldCount] = useState("--");
	const [totalActiveServices, setTotalActiveServices] = useState("--");

	const [allDropdownList, setAllDropdownList] = useState({});
	const [selectedService, setSelectedService] = useState(InitialServiceDetails);
	const [service_Type, setServiceType] = useState("CLASS");

	const [isShowOnBoarding, setShowOnBoarding] = useState(false);

	const [startEpoc, setStartEpoc] = useState("");
	const [endEpoc, setEndEpoc] = useState("");
	const [isPurchaseExecuted, setPurchaseExecuted] = useState(false);
	const [isShowScheduleDialog, setShowScheduleDialog] = useState(false);
	const [seeMoreScheduleId, setSeeMoreScheduleId] = useState(null);
	const [isServiceModifiedState, setServiceModifiedState] = useState(false);

	const [isShowDeleteDialog, setShowDeleteDialog] = useState(false);
	const [isForceUpdate, setForceUpdate] = useState(false);
 
	const [selectedFilters, setSelectedFilters] = useState({
		selectedCatalyst : [],
		selectedLocation: [],
		selectedCategory : []
	});

    const {
        details: loggedUser
    } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

    const {
       details,
       loading,
       error
    } = useSelector(({ services: { details, loading, error} }) => ({ details, loading, error }));

    const createCrumbDetails = (crumbs) => {
		const crumbArray = [];
		
		crumbs.map((crmb, index) => {
			const obj = {};

			switch(crmb) {
				case "":
					obj.path = "/";
					obj.label = "Home";
					crumbArray.push(obj);
					break;
				case "dashboard":
					obj.path = "/dashboard";
					obj.label = "My Dashboard";
					crumbArray.push(obj);
					break;
				case "settings":
					obj.path = "/dashboard/settings";
					obj.label = "Settings";
					crumbArray.push(obj);
					break;
				default: break;
				
			}

			setCrumb(crumbArray);
		});
	}

    const onHandleEventScheduleClick = (cls) => {
    	setSelectedService(cls);
    	//setShowSchedule(true);
    }

    const onHandleScheduleNow = (val) => {
    	//setShowSchedule(val);
  	}

	const onHandleItemClick = () => {
		
	}

    const onHandleServiceTitleClick = (eachservice) => {
    	const {serviceId} = eachservice;
    	navigate(`/dashboard/event-details/${serviceId}`);
    }

    const onCheckboxSelect = (name, val) => {
    	setSelectedFilters({...selectedFilters, [name] : val});
    }

    const applyFilter = () => {
  
    	const { selectedCatalyst = [], selectedLocation = [], selectedCategory = [] } = selectedFilters || {};

    	let filterArray = allSchedules;

    	if(selectedCatalyst.length) {
    		filterArray = allSchedules.filter((item, index) => selectedCatalyst.includes(item?.serviceDetails?.userDetails?.userId));
    	}

    	if(selectedCategory.length) {
    		filterArray = filterArray.filter((item, index) => selectedCategory.includes(item?.serviceDetails?.serviceCategoryDetails?.serviceCategoryId));
    	}

    	if(selectedLocation.length) {
    		filterArray = filterArray.filter((item, index) => selectedLocation.includes(item?.serviceDetails?.serviceLocation?.city));
    	}

    	setFilterSchedules(filterArray);
    }

    const handleCTAClick = () => {
    	setShowDialog(true);
    }

    const onHandleMenuClick = (id = 0, item = {}) => {

    	console.log(item);
    	console.log(id)
    	if(id === "duplicate") {
    		delete item?.serviceId;
    		delete item?.scheduleDetails;
    		delete item?.serviceCreatedOn;
    		delete item?.trail;
    		item.serviceStatus = "active";
    		setServiceModifiedState(true);
    		setSelectedService(item);
    		setShowDialog(true);
    	} else if(id === "edit") {
    		delete item?.scheduleDetails;
    		delete item?.serviceCreatedOn;
    		delete item?.trail;

    		setServiceModifiedState(true);
    		setSelectedService(item);
    		setShowDialog(true);
    	} else if(id === "delete") {
    		setSelectedService(item);
    		setShowDeleteDialog(true);
    	}
    }

    const TabPanel = (service_Type = "") => {
		return (
			<>
				<div className="list_view">
					{filterServices.length === 0 && <Noresult serviceName = {service_Type} onCTAClick = {() => handleCTAClick("Classes")} /> }

					{filterServices.length > 0 &&
						<ListView 
							list = {filterServices} 
							onHandleEventScheduleClick = {(val) => onHandleEventScheduleClick(val)} 
							onHandleServiceTitleClick = {onHandleServiceTitleClick}
							onHandleMenuClick = {(id, item) => onHandleMenuClick(id, item)}
							isDisabled = {false}
						/>
					}
				</div>
			</>
		);
	}

	const getCatalystRevenue = async () => {
		const currentMonthFirstDayEpoc = getCurrentMonthFirstDayEpoc();
		const currentEpoc = new Date().getTime();
		setLoading(true);
		const URL = `${baseURL}/minded-services/catalyst/${loggedUser?.userId}/revenue?startDate=${currentMonthFirstDayEpoc}&endDate=${currentEpoc}`;

       	return await fetch(URL)
      	.then(response => {
		    const resp = response;

		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		      	const tR = parseFloat(res?.totalRevenue).toFixed(2);
		        setCatalystRevenuePrice(tR);
		      });
		    } else {
		      //Error
		      setLoading(false);
		      setError(true);
		    }
  		})
  		.catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error getCatalystRevenue', error);
		});
	}

	const getTotalTicketSoldDetails = async () => {
		setLoading(false);

		const currentMonthFirstDayEpoc = getCurrentMonthFirstDayEpoc();
		const currentEpoc = new Date().getTime();
		
		const URL = `${baseURL}/minded-services/ticketsSoldByDate?catalystId=${loggedUser.userId}&startDate=${currentMonthFirstDayEpoc}&endDate=${currentEpoc}`;

       	return await fetch(URL)
       	.then(response => {
		    const resp = response;
		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		      	let totalTicketCount = 0;
		      	res.map(item => {
		      		totalTicketCount += item.count;
		      	});

		        setTotalTicketSoldCount(totalTicketCount || 0);
		      });
		    } else {
		      	//Error
		    	setError(true);
		    }
		})
		.catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error getTotalTicketSoldDetails', error);
		});
	}

	const getActiveServices = async () => {
		setLoading(false);

		const currentMonthFirstDayEpoc = getFormattedCurrentMonthFirstDate();
		const currentEpoc = getFormattedCurrentMonthTodayDate();
		
		const URL = `${baseURL}/minded-services/getActiveServices/${loggedUser.userId}?startDate=${currentMonthFirstDayEpoc}&endDate=${currentEpoc}`;

       	return await fetch(URL, headerOption)
       	.then(response => {
		    const resp = response;
		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		        setTotalActiveServices(res?.count || 0);
		      });
		    } else {
		      	//Error
		      	
		    	setError(true);
		    }
		  })
		  .catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error getActiveServices', error);
		});
	}

	const getCatalystById = async () => {
		setLoading(true);
		const URL = `${baseURL}/minded-user/getUserById/${loggedUser?.userId}`;

       	return await fetch(URL, headerOption)
      	.then(response => {
		    const resp = response;

		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		        setUser(res);
		        dispatch(saveAdminAction(res));
		      });
		    } else {
		      //Error
		      setLoading(false);
		      setError(true);
		    }
  		})
  		.catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error getCatalystById', error);
		});
	}

	const handleDateChange = (stDate, edDate) => {
    	const type = getCatalystDetailsServiceTypeFromActiveTab(activeTab);

    	const startDateEpoc = new Date(stDate).getTime();
    	const endDateEpoc   = new Date(edDate).getTime();

    	setStartEpoc(startDateEpoc);
    	setEndEpoc(endDateEpoc);

    	//dispatch(getSchedulesAction(loggedUser?.userId, "", type, "", startDateEpoc, endDateEpoc, "", ""));
    	getSchedulesByDate(loggedUser?.userId, type, startDateEpoc, endDateEpoc);
    }

    const filterServicesData = () => {
    	const statusData = allServices.filter((srv, index) => srv?.serviceStatus === serviceStatus);
    	setFilterServices(statusData);
    }

    const getSchedulesByDate = async (uid = "", serviceType = "", startTimeEpoc = "", endTimeEpoc = "") => {
    	setLoading(true);
		const URL = `${baseURL}/minded-services/getScheduleDetails?userId=${uid}&serviceType=${serviceType}&scheduleStartDate=${startTimeEpoc}&scheduleEndDate=${endTimeEpoc}`;

       	return await fetch(URL)
      	.then(response => {
		    const resp = response;

		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		        setAllSchedules(res);
		      });
		    } else {
		      //Error
		      setLoading(false);
		      setError(true);
		    }
  		})
  		.catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error getSchedulesByDate', error);
		});
    }

    useEffect(() => {
        //let locationVal = location?.pathname;
        const crumbs = location.pathname.split("/");
        createCrumbDetails(crumbs);
    }, [location]);

	useEffect(() => {
    	if(loggedUser && loggedUser?.role) {
    		setUser(loggedUser);
    		checkPurchaseExecutedState();
    	}
    }, [loggedUser]);

    useEffect(() => {
     	if(!isPending) {
			//performAPICalls();
			setForceUpdate(true);
			const type = getCatalystDetailsServiceTypeFromActiveTab(activeTab);
	    	getSchedulesByDate(loggedUser?.userId, type, startEpoc, endEpoc);
    	}
	}, [activeTab]);

     useEffect(() => {
     	if(!isShowDialog) {
     		setServiceModifiedState(false);
     		//performAPICalls();
     		setForceUpdate(true);
     	}
     }, [isShowDialog]);

    const performAPICalls = () => {
     	const type = getCatalystDetailsServiceTypeFromActiveTab(activeTab);
	    setServiceType(type);
	    dispatch(getServicesAction(loggedUser?.userId, "", type, "", "", "", ""));
    }

    useEffect(() => {
    	filterServicesData();
    }, [serviceStatus]);

	useEffect(() => {
		if(Array.isArray(details)) {
			setAllServices(details)
		}
	}, [details]);

	useEffect(() => {
    	if(Array.isArray(allServices)) {
    		filterServicesData();
    	}
    }, [allServices]);

    useEffect(() => {
    	if(Array.isArray(allSchedules)) {	
    		const obj = generateEventsDropdownList(allSchedules);

    		setAllDropdownList(obj);
    		applyFilter();
    	}
    }, [allSchedules]);

    useEffect(() => {
    	applyFilter();
    }, [selectedFilters]);

    useEffect(() => {
    	window.scrollTo({top: 0, behavior: "smooth"});
    	const type = getCatalystDetailsServiceTypeFromActiveTab(activeTab);
    	//if(!isPending) {
	    	performAPICalls();
	    	getSchedulesByDate(loggedUser?.userId, type, startEpoc, endEpoc);
	    	getCatalystById();
	    	getActiveServices();
	    	getTotalTicketSoldDetails();
	    	getCatalystRevenue();
    	//}
    }, []);

    const onRefresh = () => {
		getCatalystById();
		getActiveServices();
	}

	const HeaderView = () => {
		return(
			<div className="w-100 report_view">
				<div className="w-100 d-flex justify-content-around align-items-center h-100">
					<div className="text-center">
						<h2 className="heading-2 color-primary-50">{totalActiveServices}</h2>
						<div className="caption-R color-primary-50">Enlisted Services</div>
					</div>

					<div className="text-center">
						<h2 className="heading-2 color-primary-50">{totalSoldTicketCount}</h2>
						<div className="caption-R color-primary-50">Tickets Sold in {currentMonth}</div>
					</div>

					<div className="text-center">
						<h2 className="heading-2 color-primary-50">
						{catalystRevenuePrice}
						{catalystRevenuePrice !== "--" && <span className="caption-R">EUR</span>}
						</h2>
						<div className="caption-R color-primary-50">Sales in {currentMonth}</div>
					</div>
				</div>
			</div>
		)
	}

	const checkPurchaseExecutedState = async () => {
		setLoading(true);
		
		const URL = `${baseURL}/minded-services/check_purchase_status/catalyst/${loggedUser?.userId}`;
       	return await fetch(URL)
      	.then(response => {
		    const resp = response;

		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		       	setPurchaseExecuted(res);
		      });
		    } else {
		      //Error
		      setLoading(false);
		      setError(true);
		    }
  		})
  		.catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error check_purchase_status', error);
		});
	}

	const getMessageAndButton = () => {
		if(!isPurchaseExecuted && subscriptionStatus === "auth_pending") {
			return {
			  text: "Your have not initiated the Payment.",
			  buttonLabel: "Pay Now",
			  buttonAction: () => setShowOnBoarding(true)
			};
		}

		if(subscriptionStatus === "declined") {
			return {
		      text: statusDescription || "Your request has been Declined",
		      buttonLabel: "Retry Now",
		      buttonAction: () => setShowOnBoarding(true)
		    };
		}

		if(subscriptionStatus === "auth_pending") {
		    return {
		      text: "Your card authentication is currently pending. Please wait until it gets approved by the bank.",
		      buttonLabel: null,
		      buttonAction: () => console.log("Initiate Payment")
		    };
		}

		if(subscriptionStatus === "auth_failed") {
			return {
			  text: "Your card authentication has been declined.",
			  buttonLabel: "Retry Now",
			  buttonAction: () => setShowOnBoarding(true)
			};
		}

		if(["auth_success_payment_pending", "payment_processing"].includes(subscriptionStatus)) {
		    return {
		      text: "Your card has been authenticated. Waiting for the bank to deduct the subscription amount.",
		      buttonLabel: null,
		      buttonAction: () => console.log("Initiate Payment")
		    };
		}

		if(subscriptionStatus === "payment_success" && status === "pending") {
		    return {
		      text: "Your request is pending for approval. Once the admin approves the request, you can schedule service(s).",
		      buttonLabel: null  // No button required
		    };
		}

		if(subscriptionStatus === "payment_failed") {
		    return {
		      text: "Your payment has failed.",
		      buttonLabel: "Pay New",
		      buttonAction: () => setShowOnBoarding(true)
		    };
		}

		if (subscriptionStatus === "payment_success" && status === "pending_payment") {
			return {
			  text: "Your payment is pending. Once the payment is completed, we will activate your profile within 12 to 24 hours.",
			  buttonLabel: null,
			  buttonAction: () => setShowOnBoarding(true)
			};
		}

  		return null;  // Default return if no condition matches
	}

	const onRescheduleBtnClick = (item = {}) => {
    	//navigate("/contact");
    	setSeeMoreScheduleId(item?.scheduleId);
    	setShowScheduleDialog(true);
    }

    const onHandleDeleteDialogClose = (isUpdate = false) => {
    	setShowDeleteDialog(false);
    	setForceUpdate(isUpdate);
    }

    useEffect(() => {
    	if(isForceUpdate) {
    		performAPICalls();
    		setForceUpdate(false);
    	}
    }, [isForceUpdate]);

	const {
		email = "",
		status = "pending",
		name = "",
		phone = "",
		catalystDetails = {}
	} = user || {};

	const {
		statusDescription = "",
		subscriptionStatus = "",
		planExpireDate = ""
	} = catalystDetails?.planDetails || {};

	const isPending = (status === "pending" || status === "pending_payment" || subscriptionStatus !== "payment_success");

	const SIBSStatusTextValue = SIBSStatusText(subscriptionStatus);
	const subscriptionEndDate = getFormattedDate(planExpireDate);

	const { text = "", buttonLabel = "", buttonAction } = getMessageAndButton() || {};

	return (
		<div className="main-no-content-padding">
			<HelmetDetails title="Catalyst Dashboard | Minded." />
			<Breadcrumb> 
                {crumb.map((item, index) => {
                	return (index !== crumb.length - 1) ? (
	                	<>
		                	<Link to={item.path}>
		                		<span className={`body-R color-secondary-100 px-2`}>{item.label}</span>
		                	</Link>
		                	<img src={right_arrow} style={{width: '12px', height: '12px'}} alt = "Right arrow" />
	                	</>
	                	) : (<span className={`body-R color-neutral-50 px-2`}>{item?.label}</span>)
                	}
                )}
	        </Breadcrumb>
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-12">
						<div className="d-flex justify-content-between">
							<div className="">
								<h1 className="heading-1 color-primary-50">My Dashboard</h1>
							</div>
						</div>
					</div>
				</div>
				
				<div className="row my-4">
					<div className="col-lg-3 col-md-4 my-3">
						<div className="card-details rounded-4 pb-4 bg-white">
							<img src={user?.profileDescription?.profilePic || avatar} className="catalyst_profile_img" alt="Avatar" />
							<div className="p-4">
								<div className="">
									<h5 className="heading-5 color-primary-50">{name}</h5>
									<div className="py-1">
										<button className="ax-button" onClick={onRefresh}>
											<img src = {Refresh} alt="Refresh" className="refresh_icon" />
										</button>
										<span className = "px-2 body-CAP-small color-primary-50 capitalize">{subscriptionStatus === "payment_success" ?  status : SIBSStatusTextValue}</span>
									</div>
								</div>
								
								<div className="body-R color-primary-50 py-1">{email || "-"}</div>
								<div className="body-R color-primary-50 pt-1">{phone || "-"}</div>
							</div>
							{!isPending && subscriptionStatus === "payment_success" && <div className="color-primary-50 caption-R fw-1 text-center mb-3">Your Subscription renews on {subscriptionEndDate}</div>}
							{!isPending && subscriptionStatus === "payment_success" && <div className="color-primary-50 caption-R fw-1 text-center mb-3">To stop the subscription mail us at <a className="color-primary-50 mail" href="mailto:hello@theminded.club">team@minded.club</a></div>}
							
							{status !== "pending_payment" && <div className="px-4 d-flex justify-content-center">
								<Link 
									to="/dashboard/profile"
									className="text-center px-3 m-auto tertiary_btn color-primary-50 capitalize" 
								>
									VIEW PROFILE
								</Link>
							</div>
							}
						</div>

						{!isPending && <div className="card-details rounded-4 pb-4 secondary_80_bg p-3 my-3">
							<h5 className="color-primary-50 heading-5">Quick Links</h5>
							<div className="d-flex flex-column align-items-start mt-3">
								<button 
									className="LINK color-secondary-100 ax-button capitalize text-left" 
									onClick={() => setShowDialog(true)}
								>
									Add services
								</button>
								<hr />
								<Link className="LINK color-secondary-100 ax-button capitalize" to="/dashboard/settings">View Settings</Link>
							</div>
						</div>
						}
					</div>

					<div className="col-lg-9 col-md-8 px-2 px-md-2 my-3">
						<HeaderView />
						<MyOrder userId = {user?.userId} isForceUpdate = {isForceUpdate} />
					</div>
				</div>

				<div className="container-fluid">
					<div className="card-details rounded-4 bg-white p-lg-5 p-3">
						{!isPending && <>
							<div className="d-flex justify-content-between align-items-center">
								<div>
									<h5 className="heading-4 color-primary-50">My Services</h5>
								</div>
								<div class="services-actions">
									<button 
										className="LINK color-secondary-100 ax-button capitalize text-left add-services" 
										onClick={() => setShowDialog(true)}
									>
										Add services
									</button>
									<SelectwithoutLabel
										isDisabled={false}
										name="serviceStatus"
										value = {serviceStatus}
						                onInputChange = {val => setServiceStatus(val)}
						                list = {Service_Status_Array} 
				            		/>
	              				</div>
							</div>
							<TabView 
								service_Type = {service_Type}
								tabs = {Catalyst_Details_Service_Tab_List}
								children = {TabPanel} 
								onTabClick = {(val) => setActiveTab(val)} 
							/>
						</>
						}

						<div className="d-flex flex-column justify-content-center align-items-center">
						    {text && <p className="py-3 text-center color-primary-50 body-R">{text}</p>}
						    {buttonLabel && (
						      <button className="btn secondary_btn" onClick={buttonAction}>
						        {buttonLabel}
						      </button>
						    )}
						</div>
					</div>
				</div>
		
				{!isPending && <div className="container-fluid my-3">
					<FilterView 
						onHandleDateChange = {handleDateChange} 
						allDropdownList = {allDropdownList}
						onCheckboxSelect = {onCheckboxSelect}
					/>
				</div>}
			
				{!isPending && <ClassListView 
					onHandleCTAClick = {(item) => onRescheduleBtnClick(item)}
					serviceList = {filterSchedules}
					service_Type = {service_Type}
					CTALabel = "See More"
					isCTADisabled = {false}
					isShowMeetingLink = {true}
					isShowParticipantCount = {true}
				/>
				}

				{isShowDialog && 
					<AddServiceDialog 
						isOpen = {isShowDialog}
						serviceinfo = {selectedService}
						onHandleClose = {() => setShowDialog(false)} 
						onHandleScheduleNow = {() => onHandleScheduleNow(true)}
						navigateStepper = {isServiceModifiedState ? 2 : 1}
					/>
				}

				{isShowOnBoarding && <OnBoardingDialog 
					isOpen = {isShowOnBoarding}
					userinfo = {user}
					onHandleClose = {() => setShowOnBoarding(false)} 
					navigateStepper = {2} 
				/>
				}
			</div>

			{isShowScheduleDialog && seeMoreScheduleId && <ParticipantDialog isOpen = {isShowScheduleDialog} scheduleId = {seeMoreScheduleId} onHandleClose = {() => {setShowScheduleDialog(false); setSeeMoreScheduleId(null)}} />}
			{isShowDeleteDialog && <DeleteDialogView serviceId = {selectedService?.serviceId} show = {isShowDeleteDialog} onHandleDeleteDialogClose = {(isUpdate) => onHandleDeleteDialogClose(isUpdate)} />}
			{(loading || isLoading) && <Loading />}
			{(error || isError) && <CustomizedSnackbars isShow={error} text="Something went wrong. Please try after sometime." />}
		</div>
	)
}

export default CatalystDashboard;