import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CustomizedSnackbars from  '../../../../components/CustomizedSnackbars';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Loading from '../../../../components/loading/Loading';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {baseURL} from '../../../../actions/Constants';

import {
  InitialServiceDetails, 
  InitialGuestDetails, 
  InitialServiceErrors
} from '../../../../utils/DefaultInitialValues';

import {
  getServiceHeaderText, 
  getServiceCTAText,
  getEpocFromMomentStartDate,
  getEpocFromMoment
} from '../../../../utils/util';

import AddEventPriceView from './AddEventPriceView';
import AdditionalInfo from '../AdditionalInfo';
import EventSchedule from './EventSchedule';
import UpdateServiceDetails from '../common/UpdateServiceDetails';
import ThankYou from '../ThankYou';

import brown_cancel from '../../../../assets/brown_cancel.png';

const EventHome = (props) => {
	const {onHandleBack, onHandleClose, eventInfo = InitialServiceDetails, navigateStepper = 1} = props;
  const [stepperView, setStepperView] = useState(1);
  const [serviceDetails, setServiceDetails] = useState(eventInfo);
  const [serviceErrors, setServiceErrors] = useState(InitialServiceErrors);
  const [isProfilePicUpdate, setProfilePicUpdate] = useState(false);

  const [serviceImageList, setServiceImageList] = useState([]);

  const [guestList, setGuestList] = useState([InitialGuestDetails]);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const [isWaitEnabled, setWaitEnabled] = useState(false);
  
  const navigate = useNavigate();
  const scheduleRef = useRef();
  const priceRef = useRef();
  let executeOnce = false;

  const {
    details: loggedUser
  } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

  const isEdit = eventInfo && eventInfo?.serviceId  && eventInfo?.serviceId !== "";
 
  useEffect(() => {
    if( navigateStepper !== 0) {
      setStepperView(navigateStepper);
    }
  }, [navigateStepper]);

	const {
    serviceName = "",
    serviceDescription = "",
    serviceType = ""
	} = serviceDetails || {};

  const onUpdateServiceInfo = (e) => {
    setServiceDetails({...serviceDetails, [e.target.name] : e.target.value});
  }

  const onUpdateServiceLocation = (name, obj = {}) => {
    if(name=== "locations") {
      if(obj && obj?.city !== "") {
        setServiceDetails({...serviceDetails, serviceLocation : {...serviceDetails?.serviceLocation, ...obj}});
      }
    } else {
      setServiceDetails({...serviceDetails, serviceLocation : {...serviceDetails?.serviceLocation, [name]: obj}});
    }
  }

  const onUpdateCategory = (e, label = "") => {
    setServiceDetails({...serviceDetails,  serviceCategoryDetails: {...serviceDetails?.serviceCategoryDetails , [e.target.name] : e.target.value, "serviceCategoryName": label}});
  }

  const onHandleDateRangeUpdate = (name = "", val = "") => {
    setServiceDetails({...serviceDetails, "serviceStartDate": getEpocFromMomentStartDate(val[0]), "serviceEndDate" : getEpocFromMoment(val[1])});
  }

  const onHandleSingleDateUpdate = (name = "", val = "") => {
    setServiceDetails(
    {
      ...serviceDetails, 
      serviceStartDate: getEpocFromMomentStartDate(val), 
      serviceEndDate : getEpocFromMomentStartDate(val)
    });
  }

  const handleTimeUpdate = (name = "", val = "") => {
    setServiceDetails({...serviceDetails, [name]: val});
  }

  const onUpdateScheduleDetails = (name, scheduleInfo = {}) => {
    setServiceDetails({...serviceDetails, [name] : scheduleInfo});
  }

  const onUpdatePriceInfo = (name = "", priceInfo = {}) =>  {
    if(!executeOnce) {
      executeOnce = true;
      setServiceDetails({...serviceDetails, [name] : priceInfo});
      setWaitEnabled(true);
    }
  }

  const onUpdateGuestInfo = (e) => {
    setServiceDetails({
      ...serviceDetails, 
      serviceScheduleDetails : {...serviceDetails.serviceScheduleDetails, [e.target.name] : e.target.value}
    });
  }

  const onUpdateContactPersonDetails = (e) => {
    setServiceDetails({...serviceDetails, serviceContactPersonDetails : {...serviceDetails?.serviceContactPersonDetails, [e.target.name] : e.target.value}});
  }

  const onServiceImgUpdate = (list = []) => {
    if(list) {
      setProfilePicUpdate(true);
      setServiceImageList(list);
      //setServiceDetails({...serviceDetails, ["serviceImg"]: list})
    }
  }

  const handleClose = () => {
    onHandleClose();
  };

  const goNext = () => {

    if(stepperView === 5) {
      handleClose();
      navigate("/dashboard");
      return;
    }

    const isValid = validate();

    if(isValid) {
      if(stepperView === 4) {
        //updateService();
      } else {
        setStepperView(stepperView + 1);
      }
    }
  }

  const validate = () => {

    let isFormValid = true;

    let isCourseNameInvalid = false;
    let isDescriptionInvalid = false;
    let isServiceCategoryInvalid = false;
    let isPriceInvalid = false;
   
    if(stepperView === 1) {
      if(serviceName === "") {
        isCourseNameInvalid  = true;
        isFormValid = false;
      }

      if(serviceDescription === "") {
        isDescriptionInvalid  = true;
        isFormValid = false;
      }

      if(serviceDetails?.serviceCategoryDetails?.serviceCategoryId === "") {
        isServiceCategoryInvalid = true;
        isFormValid = false;
      }

    } else if(stepperView === 2) {
      isFormValid = scheduleRef.current.validateEventSchedule();
    } else if(stepperView === 3) {
      setServiceDetails({...serviceDetails, serviceScheduleDetails : {...serviceDetails?.serviceScheduleDetails, guestDetails: guestList}});
      isFormValid = true;
    } else if(stepperView === 4) {
      isFormValid = priceRef.current.validateTicketDetails();

      if(!isFormValid) {
        isPriceInvalid = true;
      }
    }

    if(!isFormValid) {
      setServiceErrors({
        ...serviceErrors,
        course_name_error : isCourseNameInvalid,
        course_description_error : isDescriptionInvalid,
        course_category_error : isServiceCategoryInvalid,
        course_price_error : isPriceInvalid
      });

    } else {
      setServiceErrors({...serviceErrors, ...InitialServiceErrors});
    }

    return isFormValid; 
  }

  const updateService = async () => {

    const URL= isEdit ? `${baseURL}/minded-services/updateServiceDetails` : `${baseURL}/minded-services/addServiceDetails`;

    setLoading(true);

    const filterName =  loggedUser?.profileDescription?.publicName || loggedUser?.name;

    serviceDetails.userDetails.userId = loggedUser?.userId || "";
    serviceDetails.userDetails.userName = filterName || "";
    serviceDetails.serviceStatus = "active";
    serviceDetails.serviceType = serviceDetails?.serviceType?.toUpperCase();

    serviceDetails.servicePriceOptions = serviceDetails?.servicePriceOptions?.map((eachTicket, index) => {
      const tck = eachTicket;
      tck.serviceFee = 0;
      tck.serviceFeeType = "%";

      const selectedServiceFee = loggedUser?.catalystDetails?.planDetails?.ServiceFeeDetails?.filter(eachSrv => eachSrv.serviceName === serviceType);
      
      if(selectedServiceFee && selectedServiceFee.length > 0) {
        tck.serviceFee = selectedServiceFee[0]?.serviceFee;
        tck.serviceFeeType = selectedServiceFee[0]?.serviceFeeType;
      }
    
      return tck;
    });

    let updatedProfile = [];

    if(isProfilePicUpdate) {
      //updatedProfile = serviceDetails.serviceImg;
      //serviceDetails.serviceImg = [];
    }

    return await fetch(URL, {
      method: 'POST',
      body: JSON.stringify(serviceDetails),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      const resp = response;

      const {status = ""} = resp;
      setLoading(false);

      if(status === 200 || status === 201) {
        return response.json().then(res => { 
          if(isProfilePicUpdate) {
            uploadServiceImages(res, updatedProfile);
          } else {
            setStepperView(stepperView + 1);
          }
        });
      } else {
        setError(true);
        executeOnce = false;
      }
    })
    .catch((error) => {
      console.log('error addService/updateService', error);
      executeOnce = false;
      setLoading(false);
      setError(true);
    });
  };

  const uploadServiceImages = async (data = {}, updatedProfile = []) => {
    const URL = `${baseURL}/minded-services/uploadServiceImagesToFirebase`;

    setLoading(true);

    var imageFormData = new FormData();
    imageFormData.append('serviceId', data?.serviceId);

    for (let i = 0; i < serviceImageList.length; i++) {
      imageFormData.append('file', serviceImageList[i]);
    }
   
    setLoading(true);

    //const myHeaders = getAuthenticateHeader();

    return await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: imageFormData
    })
    .then(response => {
      const resp = response;

      const {status = ""} = resp;
      setLoading(false);

      if(status === 200 || status === 201) {
        return response.json().then(res => { 
          setStepperView(stepperView + 1);
        });
      } else {
        //Error
        //Amit - Patch to hide the service image upload error and proceed
        executeOnce = false;
        serviceDetails.serviceImg = updatedProfile || [];
        setError(true);
        setStepperView(stepperView + 1);
      }
    })
    .catch((error) => {
      console.log('error uploadServiceImages', error);
      setLoading(false);
      //Amit - Patch to hide the service image upload error and proceed
      executeOnce = false;
      serviceDetails.serviceImg = updatedProfile || [];
      setError(true);
      setStepperView(stepperView + 1);
    });
  }

  const handleBack = () => {

    if(stepperView === 1) {
      onHandleBack();
    } else {
      setStepperView(stepperView - 1);
    }
  }
  
  const addGuest = () => {
    const newArray = [...guestList];

    newArray.push({
      name: "",
      specialization: "",
      guestPic : ""
    });
    setGuestList(newArray);
  }

  const onHandleItemChange = (type="", e, updateInx = 0) => {
    if(type === "Guest") {
      guestList[updateInx][e.target.name] = e.target.value;
      const newArray = [...guestList];
      setGuestList(newArray); 
    }
  }

  const onImageUpdate = (updateInx = 0, newPic) => {
    guestList[updateInx].guestPic = newPic;
    const newArray = [...guestList];
    setGuestList(newArray);
  }

  const removeItem = (type="", delInx = 1) => {
    let updatedList = [];

    switch(type) {
      case "Guest":
        updatedList = guestList.filter((item, index) => index !== delInx);
        setGuestList(updatedList);
        break;
      default: break;
    }
  }

  useEffect(() => {
    setServiceDetails({
      ...serviceDetails, 
      ...eventInfo, 
      serviceType : "EVENT"
    });

    setServiceImageList(serviceDetails?.serviceImg);
  }, [eventInfo]);

  useEffect(() => {
    if(isWaitEnabled) {
      updateService();
      setWaitEnabled(false);
    }
  }, [isWaitEnabled]);

  useEffect(() => {
    if(isError) {
      //const newServiceDetails = JSON.parse(serviceDetails);
      //setServiceDetails(newServiceDetails);
    }
  }, [isError]);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }, []);

  const title = getServiceHeaderText(stepperView, "EVENT");
  const finalBtnText = getServiceCTAText(stepperView, "EVENT");

	return (
		<>
			<DialogActions className="w-100 p-4 mt-4">
        <div className="w-100 d-flex justify-content-between align-items-center px-0 px-lg-5 px-md-3">
          <div>
            {stepperView !== 5  && <button
              onClick={handleBack}
              aria-label="Back"
              className="ax-button color-primary-50"
            >
              <ArrowBack />
            </button>
            }
          </div>

          <h4 className="heading-4 color-primary-50">
            {title}
          </h4>

          <div>
            <button
              onClick={handleClose}
              aria-label="close"
              className="ax-button"
            >
              <img src={brown_cancel} className="close_icon" alt = "Close" />
            </button>
          </div>
          
	      </div>
	    </DialogActions>

	    <DialogContent>
        {stepperView === 1 && 
          <UpdateServiceDetails 
            serviceDetails = {serviceDetails} 
            onUpdateServiceInfo = {onUpdateServiceInfo}
            onUpdateCategory = {(e, label) => onUpdateCategory(e, label)}
            serviceImageList = {serviceImageList}
            onServiceImgUpdate = {onServiceImgUpdate}
            serviceErrors = {serviceErrors}
          />
        }

        {stepperView === 2 && 
          <EventSchedule 
            serviceDetails = {serviceDetails} 
            onUpdateServiceInfo = {onUpdateServiceInfo}
            onUpdateContactPersonDetails = {onUpdateContactPersonDetails}
            onUpdateServiceLocation = {onUpdateServiceLocation}
            onUpdateScheduleDetails = {onUpdateScheduleDetails}
            onHandleDateRangeUpdate = {onHandleDateRangeUpdate}
            onHandleSingleDateUpdate = {onHandleSingleDateUpdate}
            onHandleTimeUpdate = {handleTimeUpdate}
            ref = {scheduleRef}
            isDateChangeable = {true}
            isDayChangeable = {true}
            isTimeChangeable = {true}
          />
        }

        {stepperView === 3 && 
          <AdditionalInfo 
            serviceDetails = {serviceDetails} 
            onUpdateGuestInfo = {onUpdateGuestInfo}
            guestList = {guestList}
            addGuest = {addGuest}
            serviceErrors = {serviceErrors}
            removeItem = {removeItem}
            onHandleItemChange = {(type, e, updateInx) => onHandleItemChange(type, e, updateInx)}
            onImageUpdate = {(index, newPic) => onImageUpdate(index, newPic)}
          />
        }

        {stepperView === 4 && 
          <AddEventPriceView 
          	serviceDetails = {serviceDetails}
            onUpdatePriceInfo = {onUpdatePriceInfo}
            ref = {priceRef}
          />
        }
       
			  {stepperView === 5 && <ThankYou serviceType = "Event" />}
	    </DialogContent>

      <DialogActions className="d-flex justify-content-between align-items-center bg-white">
      	<div className="container-fluid">
          <div className=" d-flex justify-content-between align-items-center dialog_padding">
            <div className="d-flex ">
            </div>
            
            <div>
              {/*(stepperView !== 1) || (stepperView !== 5) && <button className="px-5 py-3" onClick = {goNext}>Save</button>*/}
              <button 
                className="mx-3 secondary_btn capitalize" 
                onClick = {goNext}
                disabled = {isEdit && stepperView === 4}
              >
                {finalBtnText}
              </button>
            </div>
          </div>
      	</div>
      </DialogActions>
      {isLoading && <Loading />}
      {isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}     
    </>
	)
}

export default EventHome;