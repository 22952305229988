import React, {useEffect, useState} from 'react';
import ScheduleListView from './ScheduleListView';
import SelectwithoutLabel from '../../../../components/selectwithoutLabel/SelectWithoutLabel';
import {getEpocFromMomentStartDate, getEndEpochAfterGivenDate, getStartEndEpochFromGivenDate} from '../../../../utils/util';
import {MyOrder_Period_Array} from '../../../../utils/DefaultInitialValues';
import CustomizedSnackbars from  '../../../../components/CustomizedSnackbars';
import Loading from '../../../../components/loading/Loading';
import {baseURL} from '../../../../actions/Constants';

const MyOrder = (props) => {
	const {userId = "", isForceUpdate = false} = props;

	const [isLoading, setLoading] = useState(false);
  	const [isError, setError] = useState(false);
	const [schedules, setSchedules] = useState([]);
	const [selectedPeriod, setSelectedPeriod] = useState("today");
	const [scheduleStartDate, setScheduleStartDate] = useState(getEpocFromMomentStartDate(new Date()));
	const [scheduleEndDate, setScheduleEndDate] = useState(getEndEpochAfterGivenDate(new Date()));
	const [isUpdate, setUpdate] = useState(false);

	const getSchedules = async () => {
		const URL = `${baseURL}/minded-services/getScheduleDetails?userId=${userId}&scheduleStartTime=${scheduleStartDate}&scheduleEndTime=${scheduleEndDate}`;
		setLoading(true);
		setError(false);
		return await fetch(URL)
	  	.then(response => {
		    const resp = response;
		    const {status = ""} = resp;
		    setLoading(false);
		  
		    if(status === 200) {
		      return response.json().then(res => {
		        setSchedules(res);	
		      });
		    } else {
		      //Error
		    }
	  	})
	  	.catch((error) => {
	    	console.log('error getSchedules', error);
	    	setError(true)
	  	});
	}

	useEffect(() => {
		const {newStartDate = 0, newEndDate = 0} = getStartEndEpochFromGivenDate(selectedPeriod);
		setScheduleStartDate(newStartDate);
		setScheduleEndDate(newEndDate);
		setUpdate(true);
	}, [selectedPeriod]);

	useEffect(() => {
		if(isUpdate) {
			getSchedules();
			setUpdate(false);
		}
	}, [isUpdate]);

	useEffect(() => {
		setUpdate(isForceUpdate);
	}, [isForceUpdate]);

	return (
		<div className="card-details rounded-4 bg-white p-lg-5 p-3">
							
			<div className="d-flex justify-content-between align-items-center">
				<div>
					<h5 className="heading-4 color-primary-50">My Orders</h5>
				</div>
				<div>
					<SelectwithoutLabel
						isDisabled={false}
						isShowNone = {false}
						name="period"
						value = {selectedPeriod}
		                onInputChange = {val => setSelectedPeriod(val)}
		                list = {MyOrder_Period_Array} 
            		/>
  				</div>
			</div>
			<div className="list_view">
				<ScheduleListView list = {schedules} />
			</div>

			{isLoading && <Loading />}
        	{isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
		</div>
	)
}

export default MyOrder;