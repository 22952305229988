import React, {useState, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import ClassHome from './classes/ClassHome';
import EventHome from './events/EventHome';
import PrivateHome from './private/PrivateHome';
import ChooseServiceView from './ChooseServiceView';

import {InitialServiceDetails, InitialServiceErrors} from '../../../utils/DefaultInitialValues';
import {getServiceHeaderText} from '../../../utils/util';
import './classDialog.css';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddServiceDialog = (props) => {
  const {
    isOpen = false, 
    onHandleClose, 
    onHandleScheduleNow, 
    serviceinfo = InitialServiceDetails, 
    navigateStepper = 1
  } = props;
  
  const [serviceType, setServiceType] = useState("");
  const [open, setOpen] = useState(false);
  const [stepperView, setStepperView] = useState(navigateStepper);

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen]);

  const onChooseType = (val) => {
    setServiceType(val);
    setStepperView(stepperView + 1);
  }

  const handleBack = () => {
    setStepperView(stepperView - 1);
  }

  const handleClose = () => {
    setOpen(false);
    onHandleClose();
  };

  useEffect(() => {
    setStepperView(navigateStepper);
  }, [navigateStepper]);

  useEffect(() => {
    if(serviceinfo) {
      setServiceType(serviceinfo?.serviceType);
    }
  }, [serviceinfo]);

  const title = getServiceHeaderText(stepperView);

  return (
    <div className="full-dialog">
      <Dialog
        fullScreen
        open = {open}
        onClose = {handleClose}
        TransitionComponent = {Transition}
      >
        {stepperView === 1 && <ChooseServiceView 
          serviceType = {serviceType} 
          handleClose = {handleClose}
          onHandleSubmit = {val => onChooseType(val)}
        />
        }

        {stepperView !== 1 && serviceType === "CLASS" && <ClassHome 
          onHandleBack = {handleBack} 
          onHandleClose = {handleClose} 
          setServiceType = {(val) => setServiceType(val)}
          serviceType = {serviceType}
          navigateStepper = {navigateStepper - 1}
          classInfo = {serviceinfo}
        />
        }

        {stepperView !== 1 && serviceType === "EVENT" && <EventHome 
          onHandleBack = {handleBack} 
          onHandleClose = {handleClose} 
          setServiceType = {(val) => setServiceType(val)}
          serviceType = {serviceType}
          navigateStepper = {navigateStepper - 1}
          eventInfo = {serviceinfo}
        />
        }
        {stepperView !== 1 && serviceType === "PRIVATE" && <PrivateHome 
          onHandleBack = {handleBack} 
          onHandleClose = {handleClose} 
          setServiceType = {(val) => setServiceType(val)}
          serviceType = {serviceType}
          navigateStepper = {navigateStepper -1}
          serviceInfo = {serviceinfo}
        />
        }
      </Dialog>
    </div>
  );
}


export default AddServiceDialog;