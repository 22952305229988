import {useState, useEffect, forwardRef, useImperativeHandle, useRef} from 'react';
import {useSelector} from 'react-redux';
import Input from '../../../../components/input/Input';
import DatePickerView from '../../../../components/DatePickerView';
import DateRangePickerView from '../../../../components/DateRangePickerView';
import FloatSelectView from '../../../../components/floatSelectView/FloatSelectView';
import TimePickerView from '../../../../components/TimePickerView';
import {Ticket_Participant_Array, ticketQuantityType_array} from '../../../../utils/DefaultInitialValues';
import MapLocationAutoComplete from '../../../../components/location/LocationAutocomplete';
import {
  WeekList,
  Location_Options_Array,
  Repeat_Options_Array,
  Service_Schedule_Type_Array,
  InitialServiceErrors
} from '../../../../utils/DefaultInitialValues';

import {timeRangeValidation} from '../../../../utils/util';
import {TimezoneList} from '../../../../utils/DefaultStates';

const EventSchedule = forwardRef((props, ref) => {
  const dialogRef = useRef();

  const {
    serviceDetails = {},
    onUpdateScheduleDetails,
    onUpdateContactPersonDetails,
    onUpdateServiceLocation,
    onHandleDateRangeUpdate,
    onHandleSingleDateUpdate,
    onHandleTimeUpdate,
    onUpdateServiceInfo,
    isDateChangeable = true,
    isDayChangeable = true,
    isTimeChangeable = true
  } = props;

  const {
    details: loggedUser
  } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

  const {
    serviceScheduleDetails = {},
    serviceStartDate = "",
    serviceEndDate = "",
    serviceTimezone = "",
    startTime = "",
    endTime = "",
    serviceLocation = {
      city: "",
      address: "",
      country: "",
      coordinate: {
        lat: "",
        lng: ""
      },
      venueName: ""
    }
  } = serviceDetails || {};

  const [serviceErrors, setServiceError] = useState(InitialServiceErrors);
  const [eventSchedule, setSchedule] = useState(serviceScheduleDetails);
  const [ticketQuantityType, setTicketQuantityType] = useState("limited");

  const {
    serviceMeetingLink = "",
    serviceScheduleType = "single",
    recurringOccurrence = "",
    serviceScheduleTypeConfig = [],
    serviceMode = "",
    maxParticipant = -1
  } = eventSchedule || {};

  const onScheduleChange = (e) => {
    setSchedule({...eventSchedule, [e.target.name] : e.target.value});
  }

  const onUpdateRecurringChange = (value) => {
    let newArray = [...serviceScheduleTypeConfig];
   
    const filterInx = newArray.findIndex((item, inx) => item === value);
    if(filterInx === -1) {
      newArray.push(value);
    } else {
      newArray = newArray.filter((item, index) => item !== value);
    }
    setSchedule({...eventSchedule, serviceScheduleTypeConfig : newArray});
  }

  const isItemIncluded = (value) => {
    if(serviceScheduleTypeConfig.length !== 0 && serviceScheduleTypeConfig.includes(value)) {
      return true;
    }
    return false;
  } 

  const toggleState = (value) => {
    onUpdateRecurringChange(value);
  }

  useImperativeHandle(ref, () => ({

    validateEventSchedule() {
      let isFormValid = true;
      let isStartDateInvalid = false;
      let isDateRangeInvalid = false;
      let isStartTimeInvalid = false;
      let isEndTimeInvalid = false;
      let isTimeRangeInvalid = false;
      let isTimezoneInvalid = false;
      let isRepeatInvalid = false;
      let isLocationInvalid = false;
      let isMeetingLinkInvalid = false;
      let isScheduleMaxParticipantInValid = false;

      const currentDate = new Date();
      currentDate.setHours(0,0,0,0);
      const currentEpoc = currentDate.getTime();

      const isEdit = serviceDetails && serviceDetails?.serviceId && serviceDetails?.serviceId !== "";

      if(serviceStartDate === "") {
        isStartDateInvalid = true;
        isFormValid = false;
      }
      
      if(!isEdit && serviceStartDate < currentEpoc) {
        isDateRangeInvalid = true;
        isFormValid = false;
      }

      if(startTime === "") {
        isStartTimeInvalid = true;
        isFormValid = false;
      }

      if(endTime === "") {
        isEndTimeInvalid = true;
        isFormValid = false;
      }

       if(startTime !== "" && endTime !== "") {
        isTimeRangeInvalid = !timeRangeValidation(startTime, endTime);

        if(isTimeRangeInvalid) {
          isFormValid = false;
        }
      }

      if(serviceTimezone === "") {
        isTimezoneInvalid = true;
        isFormValid = false;
      }

      if(serviceScheduleType === "recurring") {
        if(serviceEndDate === "") {
          isStartDateInvalid = true;
          isFormValid = false;
        }
      
        if(!isEdit && serviceEndDate < currentEpoc) {
          isDateRangeInvalid = true;
          isFormValid = false;
        }

        if(serviceScheduleTypeConfig?.length === 0) {
          isRepeatInvalid = true;
          isFormValid = false;
        }
      }

      if(serviceMode === "physical") {
        if(serviceLocation && serviceLocation?.city === "") {
          isLocationInvalid = true;
          isFormValid = false;
        }
      }

      if(ticketQuantityType === "unlimited") {
        eventSchedule.maxParticipant = -1;
      } else {
        if(maxParticipant === "") {
          isScheduleMaxParticipantInValid = true;
          isFormValid = false;
        }
      }

      if(serviceMode === "online") {
        if(serviceMeetingLink === "") {
          isMeetingLinkInvalid = true;
          isFormValid = false;
        }
      }
      
      /*if(ticketQuantityType === "unlimited") {
        classSchedule.maxParticipant = -1;
      } else {
        if(maxParticipant === "") {
          isScheduleMaxParticipantInValid = true;
          isFormValid = false;
        }
      }
      }*/

      if(isFormValid) {
        serviceDetails.startTime = startTime;
        serviceDetails.endTime = endTime;
        if(serviceScheduleType === "single") {
          //eventSchedule.recurringOccurrence = "";
          eventSchedule.serviceScheduleTypeConfig = [];
        } else {
          eventSchedule.recurringOccurrence = "weekly";
          eventSchedule.serviceScheduleTypeConfig = serviceScheduleTypeConfig;
        }
        
        onUpdateScheduleDetails("serviceScheduleDetails", eventSchedule);
      }

      setServiceError({
        ...serviceErrors,
        course_start_date_error : isStartDateInvalid,
        course_date_range_error : isDateRangeInvalid,
        course_start_time_error: isStartTimeInvalid,
        course_end_time_error: isEndTimeInvalid,
        course_time_range_error: isTimeRangeInvalid,
        course_timezone_error : isTimezoneInvalid,
        course_repeat_error: isRepeatInvalid,
        course_location_error: isLocationInvalid,
        course_meeting_error: isMeetingLinkInvalid,
        maxParticipant_error: isScheduleMaxParticipantInValid
      });

      return isFormValid;
    }
  }));

  const {
    course_start_date_error = false,
    course_date_range_error = false,
    course_timezone_error = false,
    course_start_time_error = false,
    course_end_time_error = false,
    course_time_range_error = false,
    course_repeat_error = false,
    course_location_error = false,
    course_meeting_error = false,
    maxParticipant_error = false
  } = serviceErrors || {};

  const {
    contactPersonName = "",
    contactPersonPhone = "",
    contactPersonEmail = ""
  } = serviceDetails?.serviceContactPersonDetails || {};

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }, []);

  useEffect(() => {
    try {
      dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } finally {

    }
  }, []);

  //console.log(loggedUser.catalystDetails.planDetails.);

	return (
		<>
      <div className="container dialog_padding" ref={dialogRef}>
    		<div className="row">
    			<div className="col-lg-6 m-auto">
            <div className="w-100 card-view bg-white flex_container_50 bg-white padding_70 border_50 my-3">
              <h5 className="heading-5 color-primary-50">2.1 Date & Time</h5>
              
              <div className="w-100 d-flex  align-items-center my-2">
                {Service_Schedule_Type_Array.map((item, index) => {
                  return (
                    <div className="pe-4 d-flex align-items-center">
                      <input 
                        id={item.label} 
                        type="radio" 
                        name="serviceScheduleType"
                        value={item.value} 
                        onChange={(e) => onScheduleChange(e)} 
                        checked = {item.value === serviceScheduleType}
                      />
                        <label for={item.label} className="px-3 body-R color-primary-50">{item.label}</label>
                    </div>
                  )}
                )}
              </div>

              {serviceScheduleType === "recurring" &&
                <div className="w-100">
                  <DateRangePickerView 
                    name = "Date"
                    value={[serviceStartDate, serviceEndDate]}
                    onHandleDateRangeUpdate = {onHandleDateRangeUpdate}
                    maxDateEpoc = {loggedUser?.catalystDetails?.planDetails?.planExpireDate}
                    isDisabled = {!isDateChangeable}
                  />
                  {course_start_date_error && <div className="text-danger pt-1">! Date is a required field</div>}
                  {course_date_range_error && <div className="text-danger pt-1">! Invalid Date Range</div>}
                </div>
              }

              {serviceScheduleType === "single" &&
                <div className="w-100">
                  <DatePickerView 
                    name = "serviceStartDate"
                    value={serviceStartDate}
                    onHandleDateUpdate = {onHandleSingleDateUpdate}
                    maxDateEpoc = {loggedUser?.catalystDetails?.planDetails?.planExpireDate}
                    isDisabled = {!isDateChangeable}
                  />
                  {course_start_date_error && <div className="text-danger pt-1">! Date is a required field</div>}
                </div>
              }

              <div className="d-flex w-100">
                <div className="w-100 pe-2">
                  <TimePickerView 
                    name="startTime"
                    value={startTime}
                    onTimeChange = {(name, time) => onHandleTimeUpdate(name, time)}
                    isDisabled = {!isTimeChangeable}
                  />
                  {course_start_time_error && <div className="text-danger pt-1">! Course start time is a required field</div>}
                </div>
                <div className="w-100">
                  <TimePickerView 
                    name="endTime"
                    value={endTime}
                    onTimeChange = {(name, time) => onHandleTimeUpdate(name, time)}
                    isDisabled = {!isTimeChangeable} 
                  />
                    {course_end_time_error && <div className="text-danger pt-1">! Course end time is a required field</div>}
                </div>
              </div>
              {course_time_range_error && <div className="text-danger">! Course time range is Invalid</div>}

              <div className="col-lg-12 w-100">
                <FloatSelectView 
                  value = {serviceTimezone} 
                  className = "w-100" 
                  name = "serviceTimezone"
                  label="Timezone"
                  onInputChange = {(e) => onUpdateServiceInfo(e)} 
                  list={TimezoneList}
                  isDisabled = {!isTimeChangeable}
                />
                {course_timezone_error && <div className="text-danger pt-1">! Timezone is a required field</div>}
              </div>


              <div className="flex_container_50 w-100">
                <h5 className="heading-5 color-primary-50">Maximum Tickets</h5>
              
                <div className="d-flex w-100">
                  {ticketQuantityType_array.map((item, index) => {
                    return (
                      <div className="d-flex">
                        <input
                          id={item.label}
                          name = "ticketQuantityType"
                          type = "radio" 
                          className = "" 
                          value = {item.value}
                          checked = {item.value === ticketQuantityType} 
                          onChange = {e => setTicketQuantityType(e.target.value)}
                        />
                        <label for={item.label} className="px-4 color-primary-50">
                          <div>{item.label}</div>
                        </label>
                      </div>
                    )
                  }
                  )}
                </div>

                {ticketQuantityType === "limited" && 
                  <>
                    <div className="w-100">
                     
                      <div className="d-flex w-100">
                        {Ticket_Participant_Array.map((item, index) => {
                          return (
                            <div className="color-secondary-90_bg me-3 rounded-2">
                              <input
                                id={item.label}
                                name = "maxParticipant"
                                type = "radio" 
                                className = "opacity_0 participant" 
                                value = {item.value}
                                checked = {item.value == maxParticipant} 
                                onChange = {e => onScheduleChange(e)}
                              />
                              <label for={item.label} className="px-4 py-3 color-primary-50">
                                <div>{item.label}</div>
                              </label>
                            </div>
                          )
                        }
                        )}
                      </div>

                      <div className="w-100 mt-3">
                        <Input
                          name="maxParticipant"
                          label="Custom"
                          placeHolder="xxx" 
                          value={maxParticipant}
                          type="number"
                          onInputChange = {e => onScheduleChange(e)}
                        />
                      </div>

                      {maxParticipant_error && <div className="text-danger pt-1"> ! Please choose Maximum participant</div>}
                    </div>
                  </>
                }
                {ticketQuantityType !== "limited" && <div className="body-R color-primary-50">Unlimited tickets will be available</div>}  
              </div>

              {serviceScheduleType === "recurring" && <div className="col-lg-12 w-100 my-2">
                <FloatSelectView
                  label = "Repeat"
                  className = "w-100"
                  name = "serviceScheduleTypeConfig"
                  list = {Repeat_Options_Array}
                  value = {recurringOccurrence}
                  onInputChange = {(e) => onUpdateScheduleDetails(e)}
                />
                
                <div className="week-list mt-3">
                  {WeekList.map((item, index) => {
                    const isChecked = isItemIncluded(item.value);
                    
                    return (
                      <div className="color-secondary-90_bg rounded-2">
                        <input
                          id={`checkbox_${item.value}`}
                          name = "weekday"
                          type = "checkbox" 
                          className = "a11y weekday" 
                          value = {item.value} 
                          onChange = {() => toggleState(item.value)}
                          checked={isChecked}
                        />
                        <label for={`checkbox_${item.value}`} className="px-4 py-3 color-primary-50">
                          <div>{item.label}</div>
                        </label>
                      </div>
                    )}
                  )}
                </div> 
                {course_repeat_error  && <div className="text-danger pt-1">! Please choose repeat options</div>}          
              </div>
            }
            </div>

            <div className="w-100 card-view bg-white flex_container_50 bg-white padding_70 border_50 my-3">
              <h5 className="heading-5 color-primary-50">2.2 Location</h5>

              <div className="d-flex align-items-center">
                {Location_Options_Array.map((item, index) => {
                    return (
                      <div className="pe-4 d-flex align-items-center my-2">
                        <input 
                          id={item.value} 
                          type="radio" 
                          name="serviceMode" 
                          value = {item?.value} 
                          onChange= {(e) => onScheduleChange(e)} 
                          checked={serviceMode === item.value} 
                        />
                        <label for={item.value} className="px-3 body-R color-primary-50">{item.label}</label>
                      </div>
                    )}
                )}
              </div>

              {serviceMode === "physical" && <div className="w-100">
                <div className="w-100 mb-3">
                  <Input
                    name="venueName"
                    label="Venue name (optional)"
                    placeHolder="xxx" 
                    value={serviceLocation?.venueName}
                    onInputChange={(e) => onUpdateServiceLocation(e.target.name, e.target.value)} 
                  />
                </div>
              </div>}

              {serviceMode === "physical" && <div className="w-100">
                <MapLocationAutoComplete 
                  id="location" 
                  name="locations"
                  value={serviceLocation?.address}
                  selectedAddress = {serviceLocation?.address}
                  onSelectLocation = {(obj) => onUpdateServiceLocation("locations", obj)} 
                />
                <div className="color-primary-50 caption-R">Please provide the full address so clients know where to find you</div>
                {course_location_error && <div className="text-danger pt-1"> ! Choose a service location</div>}
              </div>}

              {serviceMode === "online" && <div className="w-100">
                <Input
                  name="serviceMeetingLink"
                  label="Meeting link"
                  placeHolder="xxx" 
                  value={serviceMeetingLink}
                  onInputChange={(e) => onScheduleChange(e)} 
                />
                {course_meeting_error && <div className="text-danger pt-1">Meeting link is a required field</div>}
              </div>}

              {/*serviceMode === "physical" && <div className="map_view w-100 py-3">
               <MapView serviceLocation = {serviceLocation} />
              </div>*/}
            </div>

            {serviceMode === "physical" && <div className="w-100 card-view bg-white flex_container_50 bg-white padding_70 border_50 my-3">
              <h5 className="heading-5 color-primary-50">2.3 Contact Details</h5>
                
              <div className="w-100">
                <Input
                  name="contactPersonName"
                  label="Contact information (optional)"
                  placeHolder="xxx" 
                  value={contactPersonName}
                  onInputChange={(e) => onUpdateContactPersonDetails(e)} 
                />
                <div className="caption-R color-primary-50">A contact for your Minder to reach if needed</div>
              </div>

              <div className="w-100">
                <Input
                  name="contactPersonEmail"
                  label="Contact email (optional)"
                  placeHolder="xxx"
                  type="email"
                  value={contactPersonEmail}
                  onInputChange={(e) => onUpdateContactPersonDetails(e)} 
                />
              </div>

              <div className="w-100">
                <Input
                  name="contactPersonPhone"
                  label="Contact mobile (optional)"
                  placeHolder="xxxxxxxxx"
                  value={contactPersonPhone}
                  onInputChange={(e) => onUpdateContactPersonDetails(e)} 
                />
              </div>
            </div>
            }
	        </div>
	      </div>
			</div>
		</>
	)
});

export default EventSchedule;