import {useEffect, useRef} from 'react';
import Input from '../../../components/input/Input';
import MultiSelectDropdown from '../../../components/multi-select-dropdown/MultiSelectDropdown';
import { useCategories } from '../../../hooks/useCategories';

const AddObjective = (props) => {
  const dialogRef = useRef();
  const { getAllCategories, categoriesList } = useCategories()
	
  const {
  	userDetails = {},  
  	userErrors = {}, 
  	onDropdownChange,
    onHandleAcountChange
  } = props;

  const {
    accountDetails,
    profileDescription
  } = userDetails || {};

	const {
		interestCategories = [],
		stuggleCategories = []
	} = profileDescription|| {}; 

  const {
    taxNumber = ""
  } = accountDetails || {};

  useEffect(() => {
    try {
      dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      getAllCategories();
    } finally {
    }
  }, []);


  const {
    interestCategoriesError = false,
    stuggleCategoriesError = false,
    taxNumberError = false
  } = userErrors || {};

	return (
		<div className="container dialog_padding" ref={dialogRef}>
  		<div className="row">
    		<div className="col-lg-6 m-auto">
			    <div className="w-100 card-view bg-white flex_container_50 bg-white padding_70 border_50 my-3">
      			<h4 className="heading-5 color-primary-50">2.1 Additional Information</h4>

      			<label for="interestCategories" className="heading-6 color-primary-50">What interests you the most... *</label>
      			<div className="my-1 w-100 select">
              <MultiSelectDropdown name="interestCategories" id="interestCategories" selectedValue = {interestCategories} onDropdownChange = {(name, list) => onDropdownChange(name, list)} options = {categoriesList} />
            </div>
            {interestCategoriesError && <div className="text-danger">! Interest Category is a required field</div>}

            <div className="mb-4 LINK color-secondary-100 capitalize fs-6">Not able to find your interest? 
          		<a className="ax-button fw-bold LINK color-secondary-100 fs-6" href="mailto:hello@theminded.club"> Propose new interest</a>
        		</div>

            <label for="stuggleCategories" className="heading-6 color-primary-50">I’m here to explore...*</label>
      			<div className="my-1 w-100 select">
              <MultiSelectDropdown name="stuggleCategories" id="stuggleCategories" selectedValue = {stuggleCategories} onDropdownChange = {(name, list) => onDropdownChange(name, list)} options = {categoriesList} />
            </div>
            {stuggleCategoriesError && <div className="text-danger">! Stuggle Category is a required field</div>}

            <div className="mb-4 LINK color-secondary-100 capitalize fs-6">Not able to find your interest? 
          		<a className="ax-button fw-bold LINK color-secondary-100 fs-6" href="mailto:hello@theminded.club"> Propose new interest</a>
        		</div>
	        </div>

          <div className="w-100 card-view bg-white flex_container_50 bg-white padding_70 border_50 my-3">
            <h4 className="heading-5 color-primary-50">2.2 Additional Information</h4>
            <div className="w-100">
              <Input 
                id="taxNumber"
                placeHolder = "xxxx-xxxx-xxxx-xxxx" 
                label = "Tax number"
                name="taxNumber" 
                classVal="w-100"
                value={taxNumber}
                isRequired = {true}
                onInputChange = {e => onHandleAcountChange(e)}
              /> 
            </div>
            {taxNumberError && <div className="text-danger">! Tax Number is a required field</div>}
          </div>


	      </div>


	    </div>
    </div>
	)
}

export default AddObjective;