import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Loading from '../../../components/loading/Loading';
import CalendarView from '../../../components/calendarView/CalendarView';
import CustomizedSnackbars from  '../../../components/CustomizedSnackbars';
import Dropdown from '../../../components/dropdown/Dropdown';
import {baseURL} from '../../../actions/Constants';
import {getSchedules} from '../../../actions/schedules.actions';

import {getSelectedDateSchedules, getDateFromEpoc} from '../../../utils/util';
import {headerOption} from '../../../utils/DefaultInitialValues';
import {getTimeZoneKey} from '../../../utils/DefaultStates';
import  './schedule-calendar.css';


const PlanScheduleCalendar = (props) => {
	const dispatch = useDispatch();

	const {bookingDetails = [], currentSchedules = {}, setCurrentSchedules, purchaseId = ""} = props;

	const [selectMonthVal, setSelectedMonth] = useState({id: 1, value: 1});
	const [startDate, setStartDate] = useState(0);
	const [endDate, setEndDate] = useState(0);

	const [purchaseDetails, setPurchaseDetails] = useState({});
	const [monthWiseBookingDetails, setMonthWiseBookingDetails] = useState([]);
	const [currentFilterBookings, setCurrentFilteredBookings] = useState({}); 

	const [allSchedules, setSchedules] = useState([]);
	const [planDuration, setPlanDuration] = useState(0);

	const [dateWiseSchedules, setDateWiseSchedules] = useState([]);
	
	const [dateVal, setDate] = useState(new Date());
	const [serviceId, setServiceId] = useState("");
	const [isError, setError] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const [monthDropdownList, setMonthDropdownList] = useState([]);

	const getSchedulesAction = getSchedules;

	const getPurchaseDetails = async () => {
	   	setLoading(true);

	    const URL = `${baseURL}/minded-services/getPurchaseDetails?purchaseId=${purchaseId}`;

	    return await fetch(URL, headerOption)
	    .then(response => {
	      const resp = response;

	      const {status = ""} = resp;
	      setLoading(false);
	      if(status === 200 || status === 201) {
	        return response.json().then(res => {
	         	// setError(false);
	          	setPurchaseDetails(res);
	        });
	        
	      } else {
	        //Error
	       setError(false);
	       alert("Error")
	      }
	    })
	    .catch((error) => {
	      setLoading(true);
	      console.log('error bookSchedule', error);
	      setError(true);
	    });
  	}

	const {
       details: schedulesDetails,
       loading: schedulesLoading,
       error: schedulesError
    } = useSelector(({ schedules: { details, loading, error} }) => ({ details, loading, error }));

	useEffect(() => {
		getPurchaseDetails();
		const srvId = bookingDetails && bookingDetails[0] && bookingDetails[0]?.serviceId;

		if(srvId) {
			setServiceId(srvId);
			dispatch(getSchedulesAction("", srvId, "", "", new Date().getTime(), "", "active", ""));
		}
	}, []);

	useEffect(() => {
		if(Array.isArray(purchaseDetails)) {
			const planValidity = purchaseDetails && purchaseDetails[0] && purchaseDetails[0]?.purchaseAdditionalDetails?.bookingList[0]?.planValidityDuration || 0;
			
			setPlanDuration(planValidity);
			const monthList = [...Array(planValidity).keys()].map((item, index) => {
      			const obj = {};
      			obj.label = index + 1;
      			obj.value =  index + 1;

      			return obj;
			});
			setMonthDropdownList(monthList);

			if(monthList && monthList.length > 0) {
				setSelectedMonth(monthList[0].value)
			}

			const list = [];
			
			for(var i = 0 ; i < planValidity; i++) { //TODO: 3 should change with actual plan duration month count
				const obj = {};
				obj.monthVal = (i + 1);
				obj.monthStartEpoc = 0;
				obj.monthEndEpoc = 0;
				obj.maxCount = purchaseDetails[0]?.purchaseAdditionalDetails?.bookingList[0]?.planSessionPerMonth;
				
				obj.allBookings = [];
				obj.bookedBookings = [];
				obj.nonBookings = [];
				obj.currentBookings = [];
				list.push(obj);
			}

			bookingDetails.map((item, idx) => {
				const findIdx = list.findIndex((echBook, idx) => echBook.monthVal === item?.bookingAdditionalDetails?.monthVal);

				if(findIdx !== -1) {
					list[findIdx].allBookings.push(item);

					if(item?.bookingStatus === "pending_confirmation") {
						list[findIdx].nonBookings.push(item);
					}
					
					if(item?.bookingStatus === "active") {
						list[findIdx].bookedBookings.push(item);
					}
				}
			});
			if(list && list.length > 0) {
				setCurrentFilteredBookings(list[0]);
			}

			setMonthWiseBookingDetails(list);
		}
	}, [purchaseDetails]);

	const chooseGroup = () => {
		const filterGroup = monthWiseBookingDetails.filter((item, idx) => item.monthVal === selectMonthVal);

		if(filterGroup && filterGroup.length > 0) {
			setCurrentFilteredBookings(filterGroup[0]);
		}
	}

	useEffect(() => {
		chooseGroup();
	}, [selectMonthVal]);

	useEffect(() => {
		const dateWiseSchedules = getSelectedDateSchedules(allSchedules, dateVal);
		setDateWiseSchedules(dateWiseSchedules);
	}, [allSchedules, dateVal]);

	useEffect(() => {
		if(Array.isArray(schedulesDetails)) {
			setSchedules(schedulesDetails);	
		}
	}, [schedulesDetails]);


	useEffect(() => {
		
		if(allBookings && allBookings[0]) {
			let stDateEpoc = allBookings[0].bookingAdditionalDetails?.startDateEpoc;
			let endDateEpoc = allBookings[0].bookingAdditionalDetails?.endDateEpoc;
			const todayEpoc = new Date().getTime();
			
			if(todayEpoc > stDateEpoc) {
				stDateEpoc = todayEpoc;
			}

			if(endDateEpoc < todayEpoc) {
				endDateEpoc = todayEpoc;
			}
			setStartDate(stDateEpoc);
			setEndDate(endDateEpoc);
		}
		
	}, [currentFilterBookings])

	const updateSchedule = (e, sch = {}) => {
		const {checked = false} = e.target;
		const overallBookings = [...currentSchedules];
		
		const bookedCount = bookedBookings?.length;
		const currentCount = currentBookings?.length;

		    //const occupiedCount = bookedCount + currentCount;

		const filterTicketIndex = currentBookings.findIndex((item, i) => item?.scheduleId === sch?.scheduleId);
		
		if(checked) {
			if((bookedCount + currentCount) < currentFilterBookings.maxCount) {
				setError(false);

				const nonBookingItem = nonBookings[0];
				
				//Adding all necessary booking info
				nonBookingItem.scheduleId = sch?.scheduleId;
				nonBookingItem.schedule.scheduleDate = sch?.scheduleDate; //AMIT : NEED TO DEBUG
				currentBookings.push(nonBookingItem);
				nonBookings.splice(0, 1);
				overallBookings.push(nonBookingItem);
			} else {
				setError(true);
			}
		} else {
			const item = currentBookings[filterTicketIndex];
			currentBookings.splice(filterTicketIndex, 1);

			const idx = overallBookings.findIndex((bck, i) => bck?.bookingId);
			overallBookings.splice(idx, 1);
			nonBookings.push(item);
		}

		const obj = {...currentFilterBookings};

		obj.nonBookings = nonBookings;
		obj.currentBookings = currentBookings;

		const array = [...monthWiseBookingDetails];
		array[selectMonthVal - 1] = obj;

		setMonthWiseBookingDetails(array);
 
	    setCurrentSchedules(overallBookings);
	}

	const isChecked = (schId = "") => {
		    //const newArray = [...currentBookings];
		const findIndex = currentBookings.findIndex((item, i) => item?.scheduleId === schId);

		return findIndex !== -1;
	}

	const {allBookings = [], bookedBookings = [], nonBookings = [],  currentBookings = []} = currentFilterBookings || {};
		
	return(
		<div>
			<div className="container my-5">
				<div className="row">
					<div className="col-lg-5 m-auto">
						<CalendarView 
							onDateSelect = {(val) => setDate(val)} 
							dateVal = {dateVal} 
							allEvents = {allSchedules} 
							startDate = {startDate} 
							endDate = {endDate} 
						/>

						<h5 className="subtitle-R color-primary-50 mt-4">Select Slot</h5>
						
						{dateWiseSchedules?.length === 0 && <div className="color-primary-50 body-R mt-3">No Slot available</div>}
							<div className="show_time_grid my-4 w-100">
								{dateWiseSchedules.map((item, index) => {
									return (
										<div>
                      <input id={item?.scheduleId} type="checkbox" className="a11y time_slot" checked = {isChecked(item?.scheduleId)} onChange = {(e) => updateSchedule(e, item)} />
											<label for={item?.scheduleId} className="time_conatiner">{item.serviceDetails?.startTime} {getTimeZoneKey(item?.serviceDetails?.serviceTimezone)}</label>
										</div>
									)
								})
								}
							</div>
			        </div>

			        <div className="col-lg-5">
			        	<div className="booking_details_card time_remaining_container py-3 mb-3 rounded-3">
			        		<div className="d-flex justify-content-between align-items-center w-100">	
			        			<div className="subtitle-B pt-2 color-primary-50">Choose booking Month</div>
								<Dropdown
			                    	placeHolder = "Month"
			                      	name = "quantity"
			                      	value={selectMonthVal}
			                      	onSelectChange = {(name, val) => setSelectedMonth(val)}
			                      	options = {monthDropdownList}	
			                    />
			                </div>
			        	</div>
						
						<div className="booking_details_card time_remaining_container">
							<div className="w-100 subtitle-R color-primary-50">
								
								<div>
									<div className="subtitle-B py-2">
										<div className="w-100 d-flex justify-content-between align-items-centerf">
											<div>Booked schedules</div>
											<div>{bookedBookings?.length} / {allBookings.length} Session(s)</div>
										</div>
									</div>
									{bookedBookings && bookedBookings.length === 0 && <div className="color-primary-50 subtitle-R">No bookings</div>}
									<div className="booked_list">
										{bookedBookings && bookedBookings.length !== 0 && bookedBookings?.map((item, i) => {
											const bookedCount = bookedBookings?.length;
											return (
												<>
													<div className="d-flex justify-content-between align-items-center">
														{/*<div>Month #{currentSelectionBookings.monthVal}</div>*/}
														{/*<div>{bookedCount}/{currentSelectionBookings?.bookings?.length} Sessions</div>*/}
													</div>
													<div className="d-flex justify-content-between align-items-center">
														<div>{getDateFromEpoc(item?.schedule?.scheduleDate)}</div>
														<div>{item?.schedule?.serviceDetails?.startTime} - {item?.schedule?.serviceDetails?.endTime} {getTimeZoneKey(item?.schedule?.serviceDetails?.serviceTimezone)}</div>
													</div>
													
													{i !== (bookedCount -1) && <hr />}
												</>
											)
										})}
									</div>
								</div>
							</div>
							
							<div className="w-100 subtitle-R color-primary-50">
								<div className="color-primary-50 subtitle-B">Recent schedules</div>
								{currentBookings?.length === 0 && <div className="color-primary-50 subtitle-R my-3">No bookings</div>}
							
								{currentBookings?.length > 0 && currentBookings.map((item, id) => 
									<div className="w-100 color-primary-50 subtitle-B d-flex justify-content-between my-3">
										<div>{getDateFromEpoc(item?.schedule?.scheduleDate)}</div>
										<div> {item?.schedule?.serviceDetails?.startTime} - {item?.schedule?.serviceDetails?.endTime} {getTimeZoneKey(item?.schedule?.serviceDetails?.serviceTimezone)}</div>
									</div>
								)}
							</div>
						</div>

			        </div>
			    </div>
			</div>
			{isLoading || schedulesLoading && <Loading />}
			{(schedulesError || isError) && <CustomizedSnackbars isShow={schedulesError || isError} text="You have reached to maximum bookings." showAlert={(isHide) => setError(false)} />}      
		</div>
	)
}

export default PlanScheduleCalendar;
