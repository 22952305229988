import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {convertDateToString} from '../../utils/util';

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

import './slider.css';
// import required modules
import img_placeholder from '../../assets/img_placeholder.png';
import ProgressBar from "../progress-bar/ProgressBar";
import { Navigation } from "swiper/modules";

const CatalystProgressSlider =  (props) => {

  const {list = [], onCardClick } = props;

  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        scrollbar={{
          hide: true
        }}
        modules={[Navigation]}

        className="mySwiper"
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 3,
          }
      }}
      onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
      >
        {list.map((item, index) => {
          
          const { name = ""} = item || {};

          const {publicName = "", profilePic = ""} = item?.profileDescription || {};

          const feature_array = item?.catalystDetails?.serviceCategory?.map((item) => item?.name);
          const location_array = item?.catalystDetails?.locations?.map((item) => item?.city);
  
          return (
            <SwiperSlide onClick = {() => onCardClick(item)}>
              <div className="slide_container">
                <div className="slide_img_container_catalyst">
                  <img src={profilePic || img_placeholder} className="catalyst_grid_item_img" />
                </div>

                <div className="pb-5 text-center w-100">
                  <div className="body-CAP color-primary-20">{feature_array.length > 0 && feature_array.join(" | ") || "-"}</div>
                  <h5 className="heading-5 color-primary-50">{publicName || name}</h5>
                 
                  <div className="body-CAP color-primary-20 text-center w-100">{location_array.length > 0 && location_array.join(" | ") || "-"}</div>
                </div>
              </div>
            </SwiperSlide>
            )
          })
        }
      <ProgressBar  progress={(currentIndex / (list.length - 1)) * 100}/>

      </Swiper>
    </>
  );
}

export default CatalystProgressSlider;
