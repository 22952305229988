import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes , Route} from 'react-router-dom';

import About from './pages/about/About';
import BecomeCatalyst from  './pages/catalysts/BecomeCatalyst';
import Catalysts from  './pages/catalysts/Catalysts';
import CatalystDetails from './pages/catalysts/CatalystDetails';

//import CatalystDashboard from './pages/admin/dashboard/catalyst/CatalystDashboard';
//import CustomerDashboard from './pages/admin/dashboard/customer/CustomerDashboard';
import Contact from './pages/contact/Contact';
import Community from './pages/community/Community';

import Dashboard from './pages/admin/dashboard/Dashboard';
import Events from './pages/events-classes/Events';
import Footer from './components/footer/Footer';

import Header from './components/header/Header';
import Home from './pages/start-here/Home';
import Maintenance from './pages/maintenance/Maintenance';

import ProfileDetails from './pages/admin/dashboard/ProfileDetails';
import Protected from './Protected';
import FullScreenMap from './components/map/FullScreenMap';
import ConfirmDialogView from './pages/confirmations/ConfirmDialogView';
import SIBSStatus from './pages/confirmations/SIBSStatus';

import UpdateCatalyst from  './pages/catalysts/UpdateCatalyst';
import ServiceDetails from './pages/events-classes/ServiceDetails';
import ScheduleDetails from './pages/events-classes/ScheduleDetails';
import ServiceDashboard from './pages/admin/dashboard/admin/ServiceDashboard';
import Settings from './pages/admin/dashboard/settings/Settings';

import AllCatalysts from './pages/admin/dashboard/detailsView/all-view/AllCatalysts';
import AllCustomers from './pages/admin/dashboard/detailsView/all-view/AllCustomers';
import AllServices from './pages/admin/dashboard/detailsView/all-view/AllServices';
import AllPayments from './pages/admin/dashboard/detailsView/all-view/AllPayments';
import {isMaintenance} from './actions/Constants';

import Logout from './pages/logout/Logout';

import './App.css';
import './pages/home/home.css';
import NewHome from './pages/start-here/NewHome';

const App = () => {

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }, []);

  //const isMaintenance = isMaintenance;

  return (
    <div>
        {isMaintenance && <Maintenance />}

        {!isMaintenance && <Router>
            <Header />
            <main>
              <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/about' element={<About />} />
                <Route exact path='/news' element={<Community />} />

                <Route exact path='/becomeacatalyst' element={<BecomeCatalyst />} />
                <Route exact path='/catalysts' element={<Catalysts />} />
                <Route exact path='/update-catalyst' element={<UpdateCatalyst />} />
                <Route exact path='/catalyst/:id' element={<CatalystDetails />} />
                
                <Route exact path='/contact' element={<Contact />} />

                <Route exact path='/events-classes' element={<Events />} />
                <Route exact path='/events-classes/service-details/:name' element={<ServiceDetails />} />
                <Route exact path='/events-classes/schedule-details/:name' element={<ScheduleDetails />} />
                {/*<Route exact path="/eventsViewCalendar" element={<EventsViewCalendar />} />*/}

                <Route exact path="/dashboard" element={<Protected><Dashboard /></Protected>} />
                <Route exact path="/dashboard/profile" element={<Protected><ProfileDetails /></Protected>} />
                <Route exact path="/dashboard/settings" element={<Protected><Settings /></Protected>} />

                <Route exact path="/dashboard/event-details/:id" element={<Protected><ServiceDashboard /></Protected>} />
                <Route exact path="/dashboard/catalysts" element={<Protected><AllCatalysts /></Protected>} />
                <Route exact path="/dashboard/customers" element={<Protected><AllCustomers /></Protected>} />
                <Route exact path="/dashboard/services" element={<Protected><AllServices /></Protected>} />
                <Route exact path="/dashboard/payments" element={<Protected><AllPayments /></Protected>} />

                <Route exact path="/dashboard/transaction/:id" element={<Protected><ConfirmDialogView /></Protected>} />
                <Route exact path="/map/:latitude/:longitude" element={<FullScreenMap />} />
                <Route exact path='/logout' element={<Logout />} />

                <Route exact path="/transaction/:id" element={<SIBSStatus />} />

                <Route exact path="/join" element={<NewHome />} />

                {/*<Route path="*" element={<NotFound />} />*/}
              </Routes>
            </main>
            <Footer/>
        </Router>}
    </div>
  );
}

export default App;
