import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import CatalystCard from '../../components/catalyst-card/CatalystCard';
import FilterView from '../../components/filters/FilterView';
import HelmetDetails from "../../components/HelmetDetails";
import HelmetText from '../../components/helmet-text/HelmetText';
import Loading from '../../components/loading/Loading';

import {getCatalystAll} from '../../actions/catalysts.actions';
import {generateDropdownList} from '../../utils/util';
import CustomizedSnackbars from  '../../components/CustomizedSnackbars';
import { mindedClubUserId } from '../../../src/actions/Constants';

import mobile_view_1 from '../../assets/mobile_img_block.png';
import become_catalyst_img from '../../assets/become_catalyst_img.png';
import avatar from '../../assets/avatar.png';
import './catalysts.css';
import { SwiperSlide } from 'swiper/react';
import { Swiper } from 'swiper/react';

const Catalysts = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const getCatalystAllAction = getCatalystAll;

	const [isLoading, setLoading] = useState(false);
	const [isBtnLoading, setBtnLoading] = useState(false);
	const [allCatalysts, setAllCatalysts] = useState([]);
	const [filterCatalysts, setFilterCatalysts] = useState([]);

	const [allDropdownList, setAllDropdownList] = useState({});

	const [selectedFilters, setSelectedFilters] = useState({
		selectedCatalyst : [],
		selectedLocation: [],
		selectedCategory : [],
		selectedLanguages:[]
	});

	const {
       details,
       loading,
       error
    } = useSelector(({ catalysts: { details, loading, error} }) => ({ details, loading, error }));

    const {
       details: filterDetails,
       loading: filterLoading,
       error: filterError
    } = useSelector(({ filter: { details, loading, error} }) => ({ details, loading, error }));


	const handleBecomeCatalystBtnClick = () => {
    	navigate('/becomeacatalyst');
    }

    const showCatalystDetails = (item) => {
    	navigate(`/catalyst/${item.userId}`)
    } 

    useEffect(() => {
    	window.scrollTo({top: 0, behavior: "smooth"});
    	dispatch(getCatalystAllAction());
    }, []);

	const shuffleCatalyst = (catalystList) => {
		for (var i = catalystList.length - 1; i > 0; i--) {
			var rand = Math.floor(Math.random() * (i + 1));
			[catalystList[i], catalystList[rand]] = [catalystList[rand], catalystList[i]]
		}
		return catalystList;
	}
	
    useEffect(() => {
    	if(Array.isArray(details)) {
 
    		const activeCatalsyt = details.filter(cat => cat.status === "active" && cat.userId !== mindedClubUserId);

    		setAllCatalysts(shuffleCatalyst(activeCatalsyt));
    		setFilterCatalysts(activeCatalsyt);
    		const obj = generateDropdownList(activeCatalsyt);
    		setAllDropdownList(obj);
    	}
    }, [details]);

    const handleDateChange = (stDate, edDate) => {
    	
    }

    const onCheckboxSelect = (name, val) => {
    	setSelectedFilters({...selectedFilters, [name] : val});
    }

    useEffect(() => {
    	const { selectedCatalyst = [], selectedLocation = [], selectedCategory = [], selectedLanguages = [] } = selectedFilters || {};

    	let filterArray = allCatalysts;

    	if(selectedCatalyst.length) {
    		filterArray = allCatalysts.filter((item, index) => selectedCatalyst.includes(item.userId));
    	}

    	if(selectedCategory.length) {
    		filterArray = filterArray.filter((item, index) => item?.catalystDetails?.serviceCategory?.some(r=> selectedCategory.includes(r.id)));
    	}

    	if(selectedLocation.length) {
    		filterArray = filterArray.filter((item, index) => item?.catalystDetails?.locations?.some(r=> selectedLocation.includes(r.city)));
    	}
    	if(selectedLanguages.length) {
    		filterArray = filterArray.filter((item, index) => item?.catalystDetails?.languages?.some(r=> selectedLanguages.includes(r.id)));
    	}

    	setFilterCatalysts(filterArray);

    }, [selectedFilters]);

	return (
		<>
			<HelmetDetails title="Catalysts | Minded." />
			<HelmetText />

			<div className="main-content-padding">
				<div className="mobile_view">
					<h1 className="heading-1 text-center color-primary-50">Catalyst</h1>
				</div>

				<div className="flex_container gap_8 catalyst-layout-width m-auto">
					<div className="w-100 text-center flex_container_24">
						<div className="w-100 body-CAP color-primary-50 text-center capitalize">Minder: Meet your Catalysts!</div>
						<h5 className="w-100 heading-5 color-primary-50">
							What is a <span className="fw-bold">Minder</span>
							? A Minder is someone dedicated to enhancing their life journey through focused personal and professional growth.
						</h5>
						<h5 className="w-100 heading-5 color-primary-50">
							<span className="fw-bold">Connecting </span> 
							with <span className="fw-bold">Catalysts</span> - Here, you can connect with our expert professionals, known as Catalysts, <span> to help you reach your highest potential</span>.
						</h5>

						<h5 className="w-100 heading-5 color-primary-50">Whether it’s nutrition, coaching, breathwork, or yoga, you’ll have the opportunity to <span className="fw-bold">learn and develop skills</span> that will profoundly impact your life.
						</h5>
					</div>
				</div>
				
				{filterCatalysts.length !== 0 && <div className="w-100">
					<div className="filter_section_container align-items-center w-100 justify-content-between">
						<h4 className="heading-4 color-primary-50">Learn from the best</h4>
						<FilterView 
							isHideSwitch = {true} 
							onHandleDateChange = {handleDateChange}
							allDropdownList = {allDropdownList}
							onCheckboxSelect = {onCheckboxSelect}
						/>
					</div>
				</div>}

				<div className="desktop_view">
					{filterCatalysts.length == 0 && <h5 className="color-primary-50 heading-5">No Catalyst found</h5> }
	            	{filterCatalysts && filterCatalysts.length > 0 && <div className="catalyst_grid">
						{filterCatalysts.map((item, index) => {
							return (
								<CatalystCard item = {item} onCatalystClick = {(item) => showCatalystDetails(item)} />
							)}
        				)}	
					</div>}
				</div>

				<div className="mobile_view w-100">
					{filterCatalysts.length == 0 && <h5 className="color-primary-50 heading-3">No Catalyst found</h5> }
					{filterCatalysts && filterCatalysts?.length > 0 && <>
						<Swiper  slidesPerView={1}
        				spaceBetween={20}>
						{filterCatalysts.map((item, index) => {

							const {publicName = "", profilePic = ""} = item?.profileDescription || {};
							const feature_array = item?.catalystDetails?.serviceCategory.slice(0, 4).map((im) => im.name);
							const location_array = item?.catalystDetails?.locations.filter(im => im?.city !== "").map(im => im?.city);

							return(
							<SwiperSlide>
								<div className="catalyst_item_container text-center my-3" onClick = {() => showCatalystDetails(item)}>

									<div className="mask1">
										<img src={profilePic || avatar} className="catalyst_Home_Mobile_view" />
									</div>
				              		
				              		<div className="catalyst_details">
				              			<div className="inner_container">
								            <div className="body-CAP white_text capitalize text-center w-100 mb-2">{feature_array.length > 0 && feature_array.join(" | ") || "-"}</div>
		            						<div className="body-CAP white_text text-center w-100 mb-2">{location_array?.length > 0 && location_array.join(" | ") || "-"}</div>
		            						<h3 className="heading-3 py-1 capitalize white_text mb-2">{publicName || item?.name}</h3> 
								            {/*<div className="p1 white_text subtitle-2 profile_description">
								            	{item?.profileDescription?.aboutMe}
							            	</div>*/}
						            	</div>
						        	</div>
		            			</div>
						</SwiperSlide>
	            			)
	            		}
	            		)}
						</Swiper>
					</>
					}
				</div>

				<div className="salmon_container color-secondary-90_bg">
					<div className="px-3 flex_container gap_8">
						<h3 className="w-100 body-CAP color-primary-50 capitalize text-center">Become a Catalyst</h3>
						<h5 className="w-100 heading-5 color-primary-50 text-center">Are you a facilitator, teacher, coach, service provider?</h5>
					</div>

					<div className="grid-container">
					  <div className="item">
					    <img src={become_catalyst_img} alt="" />
					  </div>

					  <div className="item">
					    <div className="flex_container_24">
							<div className="body-R color-primary-brand-a">Are you looking for an opportunity to share your expertise and make an impact in the lives of individuals seeking personal growth?</div>
							
							<div>
								<div className="body-R color-primary-brand-a">Look no further! We are excited to invite you to join our CATALYSTS.PORTAL, where you can connect with motivated individuals who are striving for a better life.</div>
								<div className="body-R color-primary-brand-a py-1">Our platform is designed to connect users with providers like you, who have a wealth of experience in offering personal growth services such as coaching, counselling, meditation, yoga, fitness, nutrition, and mindfulness.</div>
							</div>

							<div className="body-R color-primary-brand-a">As a provider on our platform, you'll have the unique chance to expand your reach and connect with a diverse audience of users who are looking to improve their wellbeing through personal growth. Our platform provides a secure and user-friendly environment, so you can focus on what you do best: providing quality services and helping people reach their goals.</div>
							
							<div className="body-R color-primary-brand-a">Join us TODAY and start expanding your reach with our CATALYSTS.PORTAL!</div>
							
								<div className="d-flex start-here-CTA my-2 w-100">
									<button className="secondary_btn capitalize px-3 LINK" onClick={handleBecomeCatalystBtnClick}>
										LEARN MORE
									</button>
								</div>
							</div>	
						</div>
					</div>
				</div>
			</div>
				
			{loading && <Loading />}
			{(error || filterError) && <CustomizedSnackbars isShow={error || filterError} text="Something went wrong. Please try after sometime." />}
		</>
	)
}

export default Catalysts;