import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { TransitionProps } from '@mui/material/transitions';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {getDateFromEpoc, getTimeFromEpoc, getLocationLink} from '../../../../utils/util';
import CustomizedSnackbars from  '../../../../components/CustomizedSnackbars';
import Table from '../../../../components/table/Table';
import Loading from '../../../../components/loading/Loading';
import {baseURL} from '../../../../actions/Constants';
import Slide from '@mui/material/Slide';
import location_icon from '../../../../assets/Location pin.png';
import brown_cancel from '../../../../assets/brown_cancel.png';
import './eachScheduleDetails.css';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ParticipantDialog = (props) => {
  const {isOpen = false, scheduleId = "", onHandleClose} = props;

  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [purchaserList, setPurchaserList] = useState([]);
 
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (state = "") => {
    setOpen(false);
    onHandleClose(state);
  };

  const handleSubmit = (e) => {
    handleClose(false)
  }

  const getBokingsByScheduleId = async () => {
    setLoading(true);
    setError(false);
    const URL = `${baseURL}/minded-services/getBookingDetails?scheduleId=${scheduleId}&bookingStatus=active`;

    return await fetch(URL)
      .then(response => {
      const resp = response;
      setLoading(false);
      const {status = ""} = resp;

      if(status === 200) {
        return response.json().then(res => {
          let purchaser = res;
          setPurchaserList(purchaser);
        });
      } else {
          setError(true);
          //Error
      }
    })
    .catch((error) => {
      console.log('error getBookings', error);
      
    });
  }

  useEffect(() => {
    getBokingsByScheduleId();
  }, []);

  const columns = [
      {
        name: "bookingId",
        label: 'Booking Id',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "userName",
        label: 'User Name',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div className="ax-button color-primary-50">
                {value}
              </div>
            );
          }
        },
      },
      {
        name: "emailId",
        label: 'Email',
        options: {
         filter: true,
         sort: true
        }
      },
      {
        name: "purchaseType",
        label: 'Purchase Type',
        options: {
         filter: true,
         sort: true
        }
      },
      {
        name: "bookingDate",
        label: 'Scheduled',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <div className="">
                      {value !== 0 ? "Scheduled" : "Not Scheduled"}
                    </div>
                  );
              }
          }
      }
  ];

  const title = "Participant Details";

  const firstBookingItem = purchaserList && purchaserList[0] || {};
  const {schedule = {}} = firstBookingItem || {};
  const {
    serviceDetails = {},
    scheduleMode = "online",
    scheduleDate = 0,
    scheduleStartTime = 0,
    scheduleEndTime = 0,
    scheduleMaxParticipants = -1
  } = schedule || {};

  const {
    serviceName = "",
    serviceTimezone = "",
    serviceLocation = {},
    serviceScheduleDetails = {}
  } = serviceDetails || {};

  const options = {
    selectableRows: false,
    filter: false,
    download: true,
    print: true,
    rowsPerPage: 100,
    sortOrder: {
      name: '',
      direction: 'asc'
    }
  };

  //console.log("purchaserList", purchaserList)

  return (
    <div className="full-dialog auth-dialog">
     
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        
        <DialogActions className="d-flex justify-content-between align-items-center p-4 auth-dialog-1">
          <div> 
          </div>

          <h4 className="heading-4 color-primary-50">
            {title}
          </h4>

          <div>
            <button     
              onClick={handleClose}
              aria-label="close"
              className="close_icon ax-button"
            >
              <img src={brown_cancel} className="close_icon" alt="" />
            </button>
          </div>
        </DialogActions>

        <DialogContent className="auth-dialog-1 color-primary-50">
          <div className="schedule_flex-container p-3 time_remaining_container rounded-3">
            <div className="px-3 schedule_flex-item">
              <div className="body-R fw-bold">Service Name</div>
              <div>{serviceName}</div>
            </div>

            <div className="px-3 schedule_flex-item">
              <div className="body-R fw-bold">Date</div>
              <div>{getDateFromEpoc(scheduleDate)}</div>
            </div>

            <div className="px-3 schedule_flex-item">
              <div className="body-R fw-bold">Timing</div>
              <div>{getTimeFromEpoc(scheduleStartTime, serviceTimezone)} - {getTimeFromEpoc(scheduleEndTime, serviceTimezone)} ({serviceTimezone})</div>
            </div>

            <div className="px-3 schedule_flex-item">
              <div className="body-R fw-bold">Max Participants</div>
              <div>{scheduleMaxParticipants}</div>
            </div>

            <div className="px-3 schedule_flex-item">
              <div className="body-R fw-bold">Session Mode</div>
              <div>{scheduleMode?.toUpperCase()}</div>
            </div>

            {scheduleMode === "online" && <div className="px-3 schedule_flex-item">
              <div className="body-R fw-bold">Meeting Link</div>
              <a href={serviceScheduleDetails?.serviceMeetingLink} target="_blank" className="color-primary-50"  rel="noreferrer">
                Join Here
                </a>
            </div>}

            {scheduleMode === "physical" && <div className="px-3 schedule_flex-item">
              <div className="body-R fw-bold">Location</div>
              <Link to={getLocationLink(serviceLocation)} target="_blank"  rel="noreferrer">
                <div className="py-2 caption-2 color-primary-50 d-flex">
                  <img src = {location_icon} style = {{width: '15px', height: '15px', marginTop: '9px'}} alt = "" />
                  <h6 className="p-2 caption-2 color-primary-50">{serviceLocation?.address?.label} , {serviceLocation?.city}</h6>
                </div>
              </Link>
              </div>
            }

          </div>
          <div className = "my-3">
            <Table title = "" headers={columns} rowData = {purchaserList} options = {options} />
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer d-flex justify-content-between align-items-center bg-white">
          <div className="container-fluid">
            <div className="card-container d-flex justify-content-between align-items-center dialog_padding">
              <div className="d-flex justify-content-between align-items-center">
              </div>

              <div className="d-flex">
                <button type="submit" className="mx-3 px-5 py-3 capitalize Link secondary_btn" onClick={e => handleSubmit(e)}>Close</button>
              </div>
            </div>
          </div>
        </DialogActions>
        {isLoading && <Loading />}
        {isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
      </Dialog>
  </div>
  );
}


export default ParticipantDialog;