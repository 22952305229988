import React, { useEffect, useState } from "react";
import BecomeVersionOfYourself from "./BecomeVersionOfYourself";
import Growth from "./Growth";
import SliderDashboard from "./SliderDashboard";
import GrowthJourney from "./GrowthJourney";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import MindedFooter from "./MindedFooter";
import { useNavigate } from "react-router-dom";
import AuthDialog from "../../components/authenticate/AuthDialog";
import { useDispatch, useSelector } from "react-redux";
import { saveAdmin } from "../../actions/admin.actions";
import { InitialUserDetails } from "../../utils/DefaultInitialValues";
import OnBoardingDialog from "../admin/onBoardingAdmin/OnBoardingDialog";
import GrowtholicPlan from "./GrowtholicPlan";
import Feeling from "./Feeling";

const NewHome = () => {
  const [isShowLoginDialog, setShowLoginDialog] = useState(false);
  const [isShow, setShow] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState(InitialUserDetails);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const startNowHandler = () => {
    console.log('open dialog')
    setShowLoginDialog(true);
  };

  const onLoginSuccess = (isSignupView = false, userinfo = {}) => {
    setShowLoginDialog(false);
    if (isSignupView) {
      setUserDetails(userinfo);
      setShow(true);
    } else {
      setLoggedIn(true);
      dispatch(saveAdmin(userinfo));
      navigate("/dashboard");
    }
  };

  const {
    details: loggedUser
  } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

  useEffect(() => {
  	setUserDetails(loggedUser);
  }, [loggedUser]);

  const isUserSignedIn = userDetails && userDetails?.userId && userDetails?.userId !== "";

  return (
    <div className="app-container">
      <BecomeVersionOfYourself />
      <Growth onClickHandler={startNowHandler} />
      <div className="main-content-padding">
        <Feeling />
      </div>
      <div className='mobile_view mx-2'>
        <div className='mobile_feel_container'>
          <span className='feeling_title_mobile'>Maybe you feel</span>
          <div className="feeling-text-container">
          <span className='feeling_title_details_mobile'>Stressed,</span>
          <span className='feeling_title_details_mobile'>Overwhelmed or</span>
          <span className='feeling_title_details_mobile'>Lacking Direction And</span>
          <span className='feeling_title_details_mobile'>Purpose</span>
          </div>
          <p className='feeling-title-details-desire_mobile'>Perhaps you desire to do more and be more, to break free from limitations. If you're uncertain about where to begin, we can help you navigate the journey ahead.</p>
          <button className="secondary_btn secondary_btn_mobile LINK capitalize" 
             onClick={startNowHandler} isLoggedIn={isUserSignedIn}>
            SUBSCRIBE TODAY
          </button>
          <p className='feeling_title_details_journey_mobile'>And find out where you’re at in your journey, and how Minded can help you get to the next stage.</p>
        </div>
      </div>
        <div className="mobile_view">
      <GrowthJourney onClickHandler={startNowHandler} isLoggedIn={isUserSignedIn} />
      </div>
      <div className="mobile_view">
        <GrowtholicPlan/>
      </div>
      <SliderDashboard onClickHandler={startNowHandler} />

      <div className="desktop_view">
        <GrowtholicPlan/>
      </div>
      <div className="desktop_view">
      <GrowthJourney onClickHandler={startNowHandler} isLoggedIn={isUserSignedIn} />
      </div>

     
      <FrequentlyAskedQuestions onClickHandler={startNowHandler} />
      <MindedFooter />

      {isShowLoginDialog && (
        <AuthDialog
          isOpen={isShowLoginDialog}
          onHandleClose={(val) => setShowLoginDialog(false)}
          onLoginSuccess={(isSignupView, userinfo) =>
            onLoginSuccess(isSignupView, userinfo)
          }
          isShowSignUp={true}
          userinfo={{}}
        />
      )}
      {isShow && (
        <OnBoardingDialog
          isOpen={isShow}
          onHandleClose={() => setShow(false)}
          setLoggedIn={(val) => setLoggedIn(val)}
          userinfo={userDetails}
        />
      )}
    </div>
  );
};

export default NewHome;
