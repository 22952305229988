import {useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import {useSelector} from 'react-redux';
import Input from '../../../../components/input/Input';
import AddTimePickerView from '../../../../components/AddTimePickerView/AddTimePickerView';
import DateRangePickerView from '../../../../components/DateRangePickerView';
import FloatSelectView from '../../../../components/floatSelectView/FloatSelectView';

import MapLocationAutoComplete from '../../../../components/location/LocationAutocomplete';
import {
  Location_Options_Array,
  InitialServiceErrors
} from '../../../../utils/DefaultInitialValues';

import {timeRangeValidation} from '../../../../utils/util';
import {TimezoneList} from '../../../../utils/DefaultStates';

const PrivateClassSchedule = forwardRef((props, ref) => {
  
  const {
    serviceDetails = {},
    onUpdateScheduleDetails,
    onUpdateContactPersonDetails,
    onUpdateServiceLocation,
    onHandleDateRangeUpdate,
    onUpdateServiceInfo,
    isDateChangeable = true,
    isDayChangeable = true,
    isTimeChangeable = true
  } = props;

  const {
    serviceScheduleDetails = {},
    serviceStartDate = "",
    serviceEndDate = "",
    serviceTimezone = "",
    serviceLocation = {
      city : "",
      venueName : ""
    }
  } = serviceDetails || {};

  const {
    details: loggedUser
  } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

  const [serviceErrors, setScheduleErrors] = useState(InitialServiceErrors);
  const [privateSchedule, setSchedule] = useState(serviceScheduleDetails);

  const onScheduleChange = (e) => {
    setSchedule({...privateSchedule, [e.target.name] : e.target.value});
  }

  /*const onUpdateServiceLocation = (val) => {
    setSchedule({...privateSchedule, serviceLocation : {...privateSchedule?.serviceLocation, ["city"] : val?.label}});
  }*/

  useImperativeHandle(ref, () => ({ 
    validateSchedule()  {
      let isFormValid = true;
      let isStartDateInvalid = false;
      let isDateRangeInvalid = false;

      let isTimeRangeInvalid = false;
      let isTimezoneInvalid = false;

      let isLocationInvalid = false;
      let isMeetingLinkInvalid = false;

      let isScheduleInvalid = false;

      const currentDate = new Date();
      currentDate.setHours(0,0,0,0);
      const currentEpoc = currentDate.getTime();

      if(serviceStartDate === "" || serviceEndDate === "") {
        isStartDateInvalid = true;
        isFormValid = false;
      }

      const isEdit = serviceDetails && serviceDetails?.serviceId && serviceDetails?.serviceId !== "";

      if(!isEdit && (serviceStartDate < currentEpoc || serviceEndDate < currentEpoc)) {
        isDateRangeInvalid = true;
        isFormValid = false;
      }

      if(serviceTimezone === "") {
        isTimezoneInvalid = true;
        isFormValid = false;
      }

      if(serviceScheduleType === "recurring") {
        if(serviceScheduleTypeConfig?.length === 0) {
          //isRepeatInvalid = true;
          isScheduleInvalid = true;
          isFormValid = false;
        }
      }

      if(serviceMode === "physical") {
        if(serviceLocation && serviceLocation?.city === "") {
          isLocationInvalid = true;
          isFormValid = false;
        }
      }

      if(serviceMode === "online") {
        if(serviceMeetingLink === "") {
          isMeetingLinkInvalid = true;
          isFormValid = false;
        }
      }
        
      const scheduleErrors = [];

      serviceScheduleTypeConfig.map((item, index) => {
       
        const obj = {};
          obj.day = item.day;
          obj.dayWiseValidateList = [];
        
          item?.dayWiseTimeList.map((eachRow, inx) => {
          
          const dayWiseObj = {};
          dayWiseObj.startTime = (eachRow?.startTime === "");
          dayWiseObj.endTime = (eachRow.endTime === "");
        
          if(eachRow?.startTime === "" || eachRow?.endTime === "") {
            isFormValid = false;
          }

          if (eachRow?.startTime !== "" && eachRow?.endTime !== "" ) {
            isTimeRangeInvalid = !timeRangeValidation(eachRow?.startTime, eachRow?.endTime);
            dayWiseObj.range = isTimeRangeInvalid;
            if(isTimeRangeInvalid) {
              isFormValid = false;
            }
          }
         
          obj.dayWiseValidateList.push(dayWiseObj)
        });
       
        scheduleErrors.push(obj);
      });

      setScheduleErrors({
        ...serviceErrors,
        course_start_date_error : isStartDateInvalid,
        course_date_range_error : isDateRangeInvalid,
        course_timezone_error : isTimezoneInvalid,
        course_schedule_error : scheduleErrors,
        course_no_schedule_error: isScheduleInvalid,
        course_location_error : isLocationInvalid,
        course_meeting_error : isMeetingLinkInvalid
      });

      if(isFormValid) {
        onUpdateScheduleDetails("serviceScheduleDetails", privateSchedule);
      }

      return isFormValid;
    }
  }));

  const addSchedule = (day = "") => {
    const newArray = [...serviceScheduleTypeConfig];
    const dayIndex = newArray.findIndex((item) => item.day === day);

    const obj = {};
    obj.day = day;

    if(dayIndex === -1) {
      obj.dayWiseTimeList = [{startTime: "", endTime : ""}];
      newArray.push(obj);
    } else {
      newArray[dayIndex].dayWiseTimeList.push({startTime: "", endTime : ""});
    }

    setSchedule({...privateSchedule, serviceScheduleTypeConfig : newArray});
    //setScheduleList(newArray);
  }

  const removeSchedule = (day, index) => {
    const newArray = [...serviceScheduleTypeConfig];
    const filterInx = newArray.findIndex((item, inx) => item.day === day);

    newArray[filterInx].dayWiseTimeList.splice(index, 1);

    if(newArray[filterInx].dayWiseTimeList.length === 0) {
      newArray.splice(filterInx, 1);
    }
    
    setSchedule({...privateSchedule, serviceScheduleTypeConfig : newArray});
    //setScheduleList(newArray);
  }

  const onTimeUpdate = (day, name, time, index = 0) => {
    const newArray = [...serviceScheduleTypeConfig];

    const filterInx = newArray.findIndex((item, inx) => item.day === day);

    const filterItem = newArray[filterInx];
    filterItem.dayWiseTimeList[index][name] = time;
   
    setSchedule({...privateSchedule, serviceScheduleTypeConfig : newArray});
    //setScheduleList(newArray);
  }

  const {
    course_start_date_error = false,
    course_date_range_error = false,
    course_timezone_error = false,
    course_schedule_error = false,
    course_no_schedule_error = false,
    course_location_error = false,
    course_meeting_error = false
  } = serviceErrors || {};
  
  const {
    serviceMeetingLink = "",
    serviceScheduleType = "recurring",
    serviceScheduleTypeConfig = [],
    serviceMode = "physical"
  } = privateSchedule || {};

  const {
    contactPersonName = "",
    contactPersonPhone = "",
    contactPersonEmail = ""
  } = serviceDetails?.serviceContactPersonDetails || {};

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }, []);

	return (
		<>
      <div className="container dialog_padding">
        <div className="row">
          <div className="col-lg-6 m-auto">
            <div className="w-100 card-view bg-white flex_container_50 bg-white padding_70 border_50 my-3">
              <h5 className="heading-5 color-primary-50">2.1 Date & Time</h5>

              <div className="container-fluid">
                <div className="row">
                  <div className="w-100">
                    <DateRangePickerView
                      name = "Date"
                      value = {[serviceStartDate, serviceEndDate]}
                      onHandleDateRangeUpdate = {onHandleDateRangeUpdate}
                      maxDateEpoc = {loggedUser?.catalystDetails?.planDetails?.planExpireDate}
                      isDisabled = {!isDateChangeable}
                    />
                    {course_start_date_error && <div className="text-danger pt-1">! Date is a required field</div>}
                    {course_date_range_error && <div className="text-danger pt-1">! Invalid Date Range</div>}
                  </div>
                </div>
              </div>

              <div className="col-lg-12 w-100 my-3">
                <FloatSelectView 
                  value = {serviceTimezone} 
                  className = "w-100" 
                  name = "serviceTimezone"
                  label="Timezone"
                  onInputChange = {(e) => onUpdateServiceInfo(e)} 
                  list={TimezoneList}
                  isDisabled = {!isTimeChangeable}
                />
                {course_timezone_error && <div className="text-danger pt-1">! Timezone is a required field</div>}
              </div>

              {["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map((day, inx) =>  (
                <>
                  <AddTimePickerView
                    day = {day}
                    addSchedule = {addSchedule} 
                    scheduleList = {serviceScheduleTypeConfig} 
                    removeSchedule = {(day, name, time, index) => removeSchedule(day, name, time, index)}
                    onTimeUpdate = {(day, name, time, index) => onTimeUpdate(day, name, time, index)}
                    error = {course_schedule_error}
                    isDisabled = {!isTimeChangeable}
                  />

                  {day !== "sunday" && <hr />}
                </>
              ))
              }
              {course_no_schedule_error && <div className="text-danger pt-1"> ! Choose a schedule</div>}
            </div>

            <div className="w-100 card-view bg-white flex_container_50 bg-white padding_70 border_50 my-3">
              <h5 className="heading-5 color-primary-50">2.2 Location</h5>

              <div className="d-flex align-items-center">
                {Location_Options_Array.map((item, index) => {
                  return (
                    <div className="pe-4 d-flex align-items-center my-2">
                      <input 
                        id={item.value} 
                        type="radio" 
                        name="serviceMode" 
                        value = {item?.value} 
                        onChange= {(e) => onScheduleChange(e)} 
                        checked={serviceMode === item.value} 
                      />
                      <label for={item.value} className="px-3 body-R color-primary-50">{item.label}</label>
                    </div>
                  )}
                )}
              </div>

              {serviceMode === "physical" && <div className="w-100">
                <div className="w-100 mb-3">
                  <Input
                    name="venueName"
                    label="Venue name (optional)"
                    placeHolder="xxx" 
                    value={serviceLocation?.venueName}
                    onInputChange={(e) => onUpdateServiceLocation(e.target.name, e.target.value)} 
                  />
                </div>
              </div>}

              {serviceMode === "physical" && <div className="w-100">
                <MapLocationAutoComplete 
                  id="location" 
                  name="locations"
                  value={serviceLocation?.address}
                  selectedAddress = {serviceLocation?.address}
                  onSelectLocation = {(obj) => onUpdateServiceLocation("locations", obj)} 
                />
                <div className="color-primary-50 caption-R">Please provide the full address so clients know where to find you</div>
                {course_location_error && <div className="text-danger pt-1"> ! Choose a service location</div>}
              </div>}

              {serviceMode === "online" && <div className="w-100">
                <Input
                  name="serviceMeetingLink"
                  label="Meeting link"
                  placeHolder="xxx" 
                  value={serviceMeetingLink}
                  onInputChange={(e) => onScheduleChange(e)} 
                />
                {course_meeting_error && <div className="text-danger pt-1">Meeting link is a required field</div>}
              </div>}

              {/*serviceMode === "physical" && <div className="map_view w-100 py-3">
                <MapView serviceLocation = {serviceLocation} />
              </div>*/}
            </div>

            {serviceMode === "physical" && <div className="w-100 card-view bg-white flex_container_50 bg-white padding_70 border_50 my-3">
              <h5 className="heading-5 color-primary-50">2.3 Contact Details</h5>
                
              <div className="w-100">
                <Input
                  name="contactPersonName"
                  label="Contact information (optional)"
                  placeHolder="xxx" 
                  value={contactPersonName}
                  onInputChange={(e) => onUpdateContactPersonDetails(e)} 
                />
                <div className="caption-R color-primary-50">A contact for your Minder to reach if needed</div>
              </div>

              <div className="w-100">
                <Input
                  name="contactPersonEmail"
                  label="Contact email (optional)"
                  placeHolder="xxx"
                  type="email"
                  value={contactPersonEmail}
                  onInputChange={(e) => onUpdateContactPersonDetails(e)} 
                />
              </div>

              <div className="w-100">
                <Input
                  name="contactPersonPhone"
                  label="Contact mobile (optional)"
                  placeHolder="xxxxxxxxx"
                  value={contactPersonPhone}
                  onInputChange={(e) => onUpdateContactPersonDetails(e)} 
                />
              </div>
            </div>
            }
	        </div>
	      </div>
			</div>
		</>
	)
});

export default PrivateClassSchedule;