import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./growtholicplan.css";

import React, { useEffect, useState } from "react";
import { growtholicPlans } from "../../actions/Constants";
import { useMediaQuery } from "@mui/material";

const GrowtholicPlan = () => {
  const isSmallDevice = useMediaQuery('(max-width:600px)')  

  const [slidesToShow, setSlidesToShow] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        setSlidesToShow(1);
      } else if (window.innerWidth < 768) {
        setSlidesToShow(2);
      } else if (window.innerWidth <= 1024) {
        setSlidesToShow(3);
      } else if (window.innerWidth <= 1440) {
        setSlidesToShow(4);
      } else  {
        setSlidesToShow(4);
      }
    };

    handleResize(); 
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  var settings = {
    infinite: false,
    slidesToShow: slidesToShow,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="gorwtholic-plan-container">
      
      <div className="gorwtholic-plan-title-container-parent">
        <div className="gorwtholic-plan-title-container">
          <span className="gorwtholic-plan-title">Some of our classes</span>
          <span className="gorwtholic-plan-title-description">
            (Included in the Growthaolic plan)
          </span>
        </div>
      </div>
      <div className="growth-plan-slider-container">
        <Slider {...settings} centerMode={slidesToShow <= 2} centerPadding={slidesToShow <= 1 ?'10px': '0px'}
        arrows
        >
          {growtholicPlans.map((growth) => {
            return (
              <div className="gorwtholic-item">
                <span className="gorwtholic-item-title">{growth.title}</span>
                <img src={growth.image} className="growtholic-image" />
                <span className="gorwtholic-item-description">
                  {growth.description}
                </span>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default GrowtholicPlan;
