import React, { useState } from "react";
import CancelIcon from "../../../../../assets/brown_cancel.png";
import Input from "../../../../../components/input/Input";
import {
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { DatePicker } from 'rsuite';
import { format } from "date-fns";
import { pt } from "date-fns/locale";
import "./promo-code-modal.css";
import CustomizedSnackbars from "../../../../../components/CustomizedSnackbars";
import { DISCOUNT_TYPE } from "../../../../../utils/util";
import { baseURL } from "../../../../../actions/Constants";
import { Margin, Padding } from "@mui/icons-material";

const APPLY_TO = {
  CATALYST: 'Catalyst',
  MINDED: 'MindedClubEvent',
  GROWTHAHOLIC: 'GrowthAholic'
}

const options = [
  { value: APPLY_TO.CATALYST, label: "FOUNDING CATALYST SUBSC." },
  { value: APPLY_TO.GROWTHAHOLIC, label: "GROWTHAHOLIC SUBSC." },
  { value: APPLY_TO.MINDED, label: "MINDED CLUB EVENT" },
];

const PromoCodeModal = ({toggleModal, getPromoCodes}) => {
  const [discountType, setDiscountType] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [reference, setReference] = useState("");
  const [discount, setDiscount] = useState("");
  const [applyTo, setApplyTo] = useState("");
  const [hasEndDate, setHasEndDate] = useState(false);
  const [hasUsagesLimit, sethasUsagesLimit] = useState(false);
  const [numberOfUses, setNumberOfUses] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  
  const payload = {
    discountType,
    code: promoCode,
    codeName: reference,
    discountValue: discount,
    applyTo,
    hasEndDate,
    expiryDate: expiryDate,
    hasUsagesLimit,
    usages: Number(numberOfUses),
    isActive:true
  }

  const createPromoCode = async () => {
    const headerOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };
  
    const URL = `${baseURL}/minded-services/promoCode`;
  
    try {
      const response = await fetch(URL, headerOptions);
      const textData = await response.text();

      if (response.status === 200 || response.status === 201) {
        setSuccess("Promo code added successfully!");
        getPromoCodes()
        toggleModal()
      } else {
        setError(textData);
      }
    } catch (error) {
      setError("Failed to connect to the server.");
    }
  };

  return (
    <div className="promo-code-modal-container">
      <div className="promo-code-modal-header">
        <h2>Create Promocode</h2>
        <div>
          <img src={CancelIcon} onClick={toggleModal} />
        </div>
      </div>
      <div className="promo-code-modal-type">
        <h4>Select type of Promocode</h4>
        <div className="promo-code-modal-discount-container">
          <div className="promo-code-modal-discount">
            <input
              id="discountEuro"
              type="radio"
              name="discount"
              value={DISCOUNT_TYPE.AMOUNT}
              onChange={(e) => setDiscountType(e.target.value)}
            />
            <label for="discountEuro">€ Discount</label>
          </div>
          <div className="promo-code-modal-discount">
            <input
              id="discountPercentage"
              value={DISCOUNT_TYPE.PERCENTAGE}
              type="radio"
              name="discount"
              onChange={(e) => setDiscountType(e.target.value)}
            />
            <label for="discountPercentage">% Discount</label>
          </div>
        </div>
      </div>
      <div className="promo-code-body-container">
        <Input
          name="promoCode"
          value={promoCode}
          label="Promocode Code (the code customers will use)"
          onInputChange={(e) => setPromoCode(e.target.value)}
        />
        <Input
          value={reference}
          name="reference"
          label="Promocode Name/Reference"
          onInputChange={(e) => setReference(e.target.value)}
        />
        <Input
          value={discount}
          name="discount"
          label="Discount"
          onInputChange={(e) => {
            const value = e.target.value
            !isNaN(value) && value.trim() !== "" && setDiscount(value);
          }}
        />
      </div>
      <div className="promo-code-body-container">
        <div className="promo-code-select">
          <label for="apply-to">APPLY TO</label>
          <FormControl fullWidth variant="standard">
            <Select id="apply-to" displayEmpty value={applyTo}>
              <MenuItem disabled value="">
                Choose one
              </MenuItem>
              {options.map(({ value, label }) => {
                return (
                  <MenuItem value={value} onClick={() => setApplyTo(value)}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="promo-code-modal-switch">
          <span>Set an end date</span>
          <label class="toggle-switch">
            <input type="checkbox" onChange = {(item) => setHasEndDate(item.target.checked)}/>
            <span class="slider"></span>
          </label>
        </div>
        <div className="promo-code-modal-date">
          <label for="valid-date">VALID UNTIL</label><br/>

          <DatePicker
            selected={expiryDate}
            onChange={(date) => setExpiryDate(date?.getTime())} // Convert to timestamp (ms)
            dateFormat="dd/MM/yyyy"
            locale={pt}
            minDate={new Date()}
          />


{/*           
          <Input
            type="date"
            id="valid-date"
            value={expiryDate}
            onInputChange={(e) => {
              const date = e.target.value;
              if (!isNaN(new Date(date).getTime())) {
                setExpiryDate(new Date(date));
            } else {
                setExpiryDate(new Date());
            } 
            }}
          />
           */}
        </div>
        <div className="promo-code-modal-switch">
          <span>Limit uses</span>
          <label class="toggle-switch">
            <input type="checkbox"  onChange = {(item) => sethasUsagesLimit(item.target.checked)}/>
            <span class="slider"></span>
          </label>
        </div>
        <Input
          value={numberOfUses}
          name="numberOfUses"
          onInputChange={(e) => {
            const value = e.target.value;
            !isNaN(value) && value.trim() !== "" && setNumberOfUses(value);
          }}
          label="Number of uses this promocode can be used"
        />
      </div>

      <div className="promo-code-modal-button-container">
        <button className="outlined" onClick={toggleModal}>CANCEL</button>
        <button className="filled" onClick={createPromoCode}>ADD</button>
      </div>
      {error.length !== 0 && <CustomizedSnackbars isShow={error.length !== 0} text={error} showAlert={(isHide) => setError("")} />}
      {success.length !== 0 &&<CustomizedSnackbars isShow={success.length !== 0} text={success} showAlert={(isHide) => setSuccess("")}
        alertSeverity="success"
        alertStyle={{
          backgroundColor: 'green !important'
        }}
      />}
    </div>
  );
};

export default PromoCodeModal;
