import CURIOS_EXPLORER from "../assets/curious_explorer.svg";
import GROWTHOLIC from "../assets/growthaolic.svg";
import FIRST_SLIDER from "../assets/slider_1.png";
import FIRST_SLIDER_RESPONSIVE from "../assets/responsive-images/slider-1.png";
import SECOND_SLIDER_RESPONSIVE from "../assets/header-02-new.jpg";
import SECOND_SLIDER from "../assets/slider_2.png";
import THIRD_SLIDER from "../assets/slider_3.png";
import GROWTHOLIC_PLAN_1 from '../assets/growtholic_plan/slider_1.jpg'
import GROWTHOLIC_PLAN_2 from '../assets/growtholic_plan/slider_2.jpg';
import GROWTHOLIC_PLAN_3 from '../assets/growtholic_plan/slider_3.jpg';
import GROWTHOLIC_PLAN_4 from '../assets/growtholic_plan/slider_4.jpg';
import GROWTHOLIC_PLAN_5 from '../assets/growtholic_plan/slider_5.jpg';
import GROWTHOLIC_PLAN_6 from '../assets/growtholic_plan/slider_6.jpg';

export const SAVE_FILTER_DATA = "SAVE_FILTER_DATA";
export const SAVE_FILTER_DATA2 = "SAVE_FILTER_DATA2";
export const GET_FILTER_DATA   = "GET_FILTER_DATA";
export const REMOVE_FILTER_DATA   = "REMOVE_FILTER_DATA";

export const SAVE_LOGGEDIN_ADMIN = "SAVE_LOGGEDIN_ADMIN";

export const GET_ADMIN_START = "GET_ADMIN_START";
export const GET_ADMIN_FAILURE = "GET_ADMIN_FAILURE";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const REMOVE_LOGGEDIN_ADMIN = "REMOVE_LOGGEDIN_ADMIN";

export const GET_SERVICES_START = "GET_SERVICES_START";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAILURE = "GET_SERVICES_FAILURE";

export const GET_CATALYSTS_START = "GET_CATALYSTS_START";
export const GET_CATALYSTS_SUCCESS = "GET_CATALYSTS_SUCCESS";
export const GET_CATALYSTS_FAILURE = "GET_CATALYSTS_FAILURE";

export const GET_CUSTOMERS_START = "GET_CUSTOMERS_START";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";

export const GET_TRANSACTIONS_START = "GET_TRANSACTIONS_START";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAILURE = "GET_TRANSACTIONS_FAILURE";

export const GET_SCHEDULES_START = "GET_SCHEDULES_START";
export const GET_SCHEDULES_SUCCESS = "GET_SCHEDULES_SUCCESS";
export const GET_SCHEDULES_FAILURE = "GET_SCHEDULES_FAILURE";


export const GET_BOOKINGS_START = "GET_BOOKINGS_START";
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS";
export const GET_BOOKINGS_FAILURE = "GET_BOOKINGS_FAILURE";

export const GOOGLE_API   = "AIzaSyBrsxPVyZnJaXVj5MjszTqEY2hzKeGXEyw";
export const baseURL = "https://api.weareminded.co";
export const isMaintenance = false;

export const mindersBasicPlanTitle = "Curious Explorer";
// TODO - Remove  this hardcoded value later
export const mindedClubUserId = "678a6f98bcd9ab341e877479";

export const growthJourneyItemsMonthly = [
    {
      subscriptionId:1,
      title: "Curious Explorer",
      titleDescription: "Perfect for newcomers and beginners.",
      icon: CURIOS_EXPLORER,
      previousPrice: "",
      price: 0,
      discountText: "",
      features: [
        "Exclusive newsletter and updates on personal growth topics.",
        "Access to Minded’s network of personal growth experts",
      ],
    },
    {
      subscriptionId:2,
      title: "Growthaolic",
      titleDescription: "Premium features to take you to the next level.",
      icon: GROWTHOLIC,
      previousPrice: 14.95,
      price: 10.00,
      isSelected:true,
      freeTrialDays: 14,
      discountText: "33% Discount",
      featureTitle: "Everything of Curious Explorer",
      additionalFeatures: [
        {title:'Access to our club online community & foruns.'},
        {title:'Connection opportunity with fellow Minders. '},
        {title:'Free expert led Masterclasses',
          descriptions:[
            'Health & Wellness',
            'Therapy & Counseling',
            'Coaching & Mentoring',
            'Holistic Practices'
          ]
        },
        {title:'Free online recurring classes: (show schedule)',
          descriptions:[
            'Meditation 1x/week',
            'Nervous System Regulation 1x/week',
            'Deep Rest Session 1x/month',
            'Discovery Lab 1x/month',
            'Monthly Habit Challenge 1x/month'
          ]
        }
      ],
    },
  ];


export const growthJourneyItemsYearly = [
    // {
    //   subscriptionId:1,
    //   title: "Growthaolic",
    //   quarterly:true,
    //   titleDescription: "Premium features to take you to the next level.",
    //   icon: GROWTHOLIC,
    //   previousPrice:44.85,
    //   price: 30.00,
    //   freeTrialDays: 14,
    //   discount:30,
    //   featureTitle: "Everything of Curious Explorer",
    //   additionalFeatures: [
    //     {title:'Access to our club online community & foruns.'},
    //     {title:'Connection opportunity with fellow Minders. '},
    //     {title:'Free expert led Masterclasses',
    //       descriptions:[
    //         'Health & Wellness',
    //         'Therapy & Counseling',
    //         'Coaching & Mentoring',
    //         'Holistic Practices'
    //       ]
    //     },
    //     {title:'Free online recurring classes: (show schedule)',
    //       descriptions:[
    //         'Meditation 1x/week',
    //         'Breathwork 1x/week',
    //         'Sound Therapy 2x/month',
    //         'Discovery Lab 1x/month',
    //         'Expert Q&A 1x/month'
    //       ]
    //     }
    //   ],
    // },
    {
      subscriptionId:3,
      title: "Growthaolic",
      titleDescription: "Premium features to take you to the next level.",
      icon: GROWTHOLIC,
      previousPrice:179.4,
      price: 100.00,
      yearly:true,
      discount:44,
      discountText: "44% Discount",
      freeTrialDays: 14,
      featureTitle: "Everything of Curious Explorer",
      additionalFeatures: [
        {title:'Access to our club online community & foruns.'},
        {title:'Connection opportunity with fellow Minders. '},
        {title:'Free expert led Masterclasses',
          descriptions:[
            'Health & Wellness',
            'Therapy & Counseling',
            'Coaching & Mentoring',
            'Holistic Practices'
          ]
        },
        {title:'Free online recurring classes: (show schedule)',
          descriptions:[
            'Meditation 1x/week',
            'Nervous System Regulation 1x/week',
            'Deep Rest Session 1x/week',
            'Discovery Lab 1x/month',
            'Monthly Habit Challenge 1x/month'
          ]
        }
      ],
    },
  ];

export const sliderItems= [
    {
      title: "Classes",
      description: `Dive into a world of enlightenment with our expert-led classes. Each session is a beacon of knowledge, lighting the path to deeper understanding and newfound skills.`,
      buttonText: "Start Now",
      logo: FIRST_SLIDER,
      responsiveLogo: FIRST_SLIDER_RESPONSIVE,
    },
    {
      title: "Workshops",
      description: `Our interactive workshops are the playgrounds of innovation. Engage hands-on, apply what you learn in real-time, and collaborate with others in a dynamic, creative environment.`,
      buttonText: "Start Now",
      logo: SECOND_SLIDER,
      responsiveLogo: SECOND_SLIDER_RESPONSIVE,

    },
    {
      title: "Exclusive Events",
      description: `Experience the synergy of our community at our events. From inspiring talks to networking opportunities, these gatherings are catalysts for growth, connection, and inspiration.`,
      buttonText: "Start Now",
      logo: THIRD_SLIDER,
    },
  ];



export const growtholicPlans = [
  {
    title: "Guided Meditation",
    description: "Learn to quiet the mind, focus, and reduce stress.",
    image: GROWTHOLIC_PLAN_1,
  },
  {
    title: "Deep Rest Session",
    description: "Sleep is your superpower - learn to relax, reset, and recharge fully.",
    image: GROWTHOLIC_PLAN_2,
  },
  {
    title: "Nervous System Regulation",
    description: "Learn conscious breathing techniques to regulate the nervous system.",
    image: GROWTHOLIC_PLAN_3,
  },
  {
    title: "Monthly Habit Challenge",
    description: "Learn to build lasting habits with themed masterclasses, challenges, daily habit tracking, and community support.",
    image: GROWTHOLIC_PLAN_4,
  },
  {
    title: "Discovery Lab",
    description: "Discover your purpose and gain clarity on your aspirations through reflections, activities, and group discussions.",
    image: GROWTHOLIC_PLAN_5,
  },
  {
    title: "Expert-Led Masterclasses",
    description: "Explore wellness and personal growth topics like mindful eating and leadership psychology.",
    image: GROWTHOLIC_PLAN_6,
  },
];



  



// https://minded.smmc.dev:8443
//https://api.oneattendance.com
/*
Ticket Purchase Flow:
When the user purchased any type of tickets (fixed, variant, free), then 
1. Need API - To get all available slots for each ticket type applied for selected schedule - this is required to show the avl tickets in UI to purchase
2. Once the purchased has been placed, each of the purchased item must be added to the booking table with a pending state (change the state to the success once the payment is successful or else failure) - backend task to monitor the payment and update the state
Note: Better to delete the entry once it is failure

Before adding each entry to the table, make sure the slot availability validation is checked at the backend
As user already came to the purchased ticket flow after selecting any schedule, then there is no flow to reschdule it

Package/Plan Purchase Flow - for any service:
1. Need API - To get all the available slots for the selected date, for any schedule
2. Once the user choose a single or multiple slots, each entry will be added to the booking table

Note: Before adding each entry to the table, make sure the slot availability validation is checked at the backend
*/