import {useEffect, useState} from 'react';
import SwitchView from '../../components/switch/SwitchView';
import {Annual_Plan_Array, Monthly_Plan_Array} from '../../utils/DefaultInitialValues';
import {getWeekDays, getTypeFromActiveTab, isEmptyJson} from '../../utils/util';
import starter_img from '../../assets/monthly_icon.png';
import ultimate_img from '../../assets/annual_icon.png';
import adv_img from '../../assets/adv_img.png';

import './subscriptionView.css';

const SubscriptionView = (props) => {

	const {
		planDetails = {}, 
		userErrors = {}, 
		onHandlePlanDetailsChange, 
		CTALabel = "Selected", 
		pageName = "", 
		isDisabled="false"
	} = props;

	const [isMonthlyView, setMonthlyView] = useState("false");

	const handleSelectPlan = (item) => {
    	setPlan(item);
    	onHandlePlanDetailsChange(item);
  	}

  	const [selectPlan, setPlan] = useState(planDetails);
  	const [planList, setPlanList] = useState(Monthly_Plan_Array);

  	const onUpdateSwitchView = (name, value) => {
  		setMonthlyView(value);
  	}

  	const getIcon = (index) => {
	    if(index === 0) {
	      return starter_img;
	    } else if (index === 1) {
	      return ultimate_img;
	    } else return adv_img;
  	}

  	useEffect(() => {
  		if(!isEmptyJson(planDetails)) {
  			setPlan(planDetails);
  		}
  	}, [planDetails]);

  	const {
  		subscribeError = false
  	} = userErrors || {};

  	const otherCTALabel = () => {
  		if(pageName == "Catalyst") {
  			return "Select";
  		}
  		return "Select";
  	}

  	const otherCTALabels = otherCTALabel();

	return (
		<div className="container">
	        <ul className="s_gqM8G none mb-5">
	            {planList.map((item, index) => {
		            const isSelected = ((selectPlan?.subscriptionId === item?.subscriptionId));
		            const icon = getIcon(index);
		            
		            return(
		              <li className="h-100 border-3">
		                <div className="sFkuq_5 my-3">
		                  	<div className="sZtzJ2B okRFjvD---textAlignment-6-center">

							  	<div className="subscription_heading color-primary-50">{item?.subscriptionPaymentDuration}</div>

			                    <div className="subscription_heading color-primary-50">
			                      {item?.subscriptionName}
			                    </div>


			                    <div className="color-primary-50 caption-R capitalize">{'Ultimate Package'}</div>
			                    <img src={icon} className="my-3 subscription_icon" />


			                    <div className="d-flex justify-content-center align-items-center opacity">
			                      	{index == 0 && <h4 className="heading-4 color-primary-50 line-through"><del>29,99</del> {item?.subscriptionPriceCurrency}</h4>}
			                      	{index == 1 && <h4 className="heading-4 color-primary-50 line-through"><del>179,88</del> {item?.subscriptionPriceCurrency}</h4>}
			                      	{item?.subscriptionPrice !== 0 && <span className="body-R color-primary-50">
				                        <span className="px-1 body-R">/</span>
				                        	{index === 0 && <span>{'month'}</span>}
				                        	{index === 1 && <span>year</span>}
				                      	</span>
			                  		}
			                    </div>

			                    <div className="d-flex justify-content-center align-items-center">
			                      {item?.subscriptionPrice !== 0 && <h4 className="heading-4 color-primary-50">{item?.subscriptionPrice} {item?.subscriptionPriceCurrency}</h4>}
			                      {item?.subscriptionPrice !== 0 && <span className="body-R color-primary-50">
			                        <span className="px-1 body-R">/</span>
			                        {index === 0 && <span>{'month'}</span>}
			                        {index === 1 && <span>year (11,14 EUR/month)</span>}
			                      </span>}
			                     
			                    </div>

			                    {index === 0 && <div className="capitalize body-R primary_btn color-primary-50 my-3">50% lifetime discount</div>}
			                    {index === 1 && <div className="capitalize body-R primary_btn color-primary-50 my-3">3 Months Free</div>}
			                </div>

		                    <ul className="sqxxbos oHUVJdh---textAlignment-4-left color-primary-50 body-R">
		                      
		                      	{item.subscription_benefit_list.map((benefit, index) => (
			                      	<div className="py-2">
			                      		<i className="fa-regular fa-circle-check"></i>
			                      		<span className="px-2">{benefit}</span>
			                      	</div>
		                      	))
		                      	}

		                      	<div className="mt-3 body-B">

		                      	<div>5% Service Fee</div>
		                    	<div>10% Events Fee</div>
		                    	</div>
		                    </ul>
		                   
		                  	 
		                    <div className="mb-5 w-100 d-flex justify-content-center m-auto pt-3 body-R">
		                      	<input 
			                        type="radio"
			                        id={`plan_${index}`}
			                        name="planDetails" 
			                        onChange = {(e) => handleSelectPlan(item)} 
			                        className="tertiary_btn w-100 capitalize a11y"
			                        value = {index}
			                        checked = {isSelected}
			                        disabled = {isSelected}
		                      	/>
								<label 
									className={`tertiary_btn w-75 color-primary-50 m-auto ${isDisabled  === "true" ? 'opacity': ""}`}
									for={`plan_${index}`}
								>
		                        	{isSelected ? "Selected" : otherCTALabels}
		                      	</label>
		                    </div>
		                  </div>          
		              </li>
		            )})
	        	}
	        </ul>
	        {subscribeError && <div className="pb-5 text-danger text-center">! Please choose a subscription Plan </div>}
      	
	    </div>
	)
}

export default SubscriptionView;