import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function CustomizedSnackbars({
  alertSeverity="error", 
  showAlert = (hide) => {}, 
  text="Something went wrong !!! Please try after some time.", 
  severityVal="error", 
  onClose=(isHide) => {},
  vertical = 'bottom',
  horizontal = 'center',
  autoHideDuration = 2000,
  alertStyle = {}
}) {
 const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    showAlert(false);
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose} className="w-100"  anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleClose} severity={alertSeverity} sx={alertStyle}>
         {text}
        </Alert>
      </Snackbar>
    </div>
  );
}
