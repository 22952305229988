import React, {useEffect, useRef, useState} from 'react';
import Input from '../../../components/input/Input';
import FloatSelectView from '../../../components/floatSelectView/FloatSelectView';
import PaymentDetailsView from '../../../components/paymentDetailsView/PaymentDetailsView';
import SubscribePaymentDetailsView from '../../../components/subscribePaymentDetailsView/SubscribePaymentDetailsView';
import {CountryList} from '../../../utils/DefaultStates';
import CustomizedSnackbars from '../../../components/CustomizedSnackbars';
import { baseURL } from '../../../actions/Constants';
import { PROMOCODE_APPLY_TO_TYPES } from '../../../utils/util';

const PaymentView = (props) => {
  const dialogRef = useRef();
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [promoCode, SetPromoCode] = useState('')
  const [appliedPromoCode, setAppliedPromoCode] = useState(null)
  
  const {
    isEdit = false,
    pageName = "onBoarding" , 
    userDetails = {},
    onHandleAcountChange,
    userErrors = {},
    isUseExistingCard = false,
    setUseExistingCard
  } = props;

  //const [paymentMode, setPaymentMode] = useState(false);
  
  useEffect(() => {
    try {
      dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if(isEdit && userId) {
        getSubscriptionDetails();
      } else {

      }
    } finally {
    }
  }, []);

  async function getPromoCodeDetails(){
      const headerOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      };
    
      const URL = `${baseURL}/minded-services/promoCode/${promoCode}`;
    
      try {
        const response = await fetch(URL, headerOptions);
        const textData = await response.text();

        if (response.ok) {  
            const data = JSON.parse(textData);
            if(data?.applyTo !==  PROMOCODE_APPLY_TO_TYPES.CATALYST){
              setError("You are not allowed to use this promocode")
              return;
            }
            setSuccess("Promo code applied successfully!");
            setAppliedPromoCode(data);
        } else {
            setError(textData);
            setAppliedPromoCode(null)
        }

    } catch (error) {
        setError("Failed to connect to the server.");
        setAppliedPromoCode(null)
    }
  }

  const getSubscriptionDetails = (userId = "") => {
    const status = false;
    setUseExistingCard(status);
    //return true;
  }

  const {
    accountStreetError = false,
    accountCityError = false,
    accountPostcodeError = false,
    accountCountryError = false
  } = userErrors || {};

  const {
    userId = "",
    accountDetails = {}
  } = userDetails || {};

  const {
    street1 = "",
    city = "",
    postcode = "",
    country = ""
  } = accountDetails || {};

  return (
    <div className="dialog-body" ref={dialogRef}>
      <div className="container dialog_padding">
        
        <div className="row">
          <div className="col-lg-10 m-auto">
            <div className="payment">
              <div className="payment_left_view">
                {/*isEdit && userId !== "" && <div className="w-100 d-flex justify-content-between align-items-center body-R color-primary-50">
                  <div>
                    Do you continue with existing card
                    <div className="bg-light fs-6">(...xxxx)</div>
                  </div>
                  <BasicSwitch name="isUseExistingCard" value = {isUseExistingCard} onInputChange = {(e, val) => setUseExistingCard(val) } />
                </div>*/}
                <div className="payment_card_container">
                  <h4 className="color-primary-50 heading-4">Address</h4>
                  <div className="body-R color-primary-50 pt-2 pb-5">* We currently support VISA, MASTERCARD, UPI.</div>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 my-2">
                        <Input 
                          type="text" 
                          id="street1" 
                          placeholder = "xxxx-xxxx-xxxx-xxxxx" 
                          label = "street no" 
                          name="street1" 
                          classVal="w-100"
                          value={street1}
                          onInputChange = {e => onHandleAcountChange(e)}
                          isDisabled={isUseExistingCard}
                        />
                        {accountStreetError && <div className="text-danger pt-1">! Street is a required field</div>}
                      </div>
                      <div className="col-lg-6 my-2">
                        <Input 
                          type="text" 
                          id="postcode" 
                          placeholder = "xxxxxx" 
                          label = "Zip/postal code"
                          name="postcode" 
                          classVal="w-100"
                          value={postcode}
                          onInputChange = {e => onHandleAcountChange(e)}
                          isDisabled={isUseExistingCard}
                        />
                        {accountPostcodeError && <div className="text-danger pt-1">! Postal code is a required field</div>}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 my-2">
                        <Input 
                          type="text" 
                          id="city" 
                          placeholder = "xxxx" 
                          label = "City" 
                          name="city" 
                          classVal="w-100"
                          value={city}
                          onInputChange = {e => onHandleAcountChange(e)}
                          isDisabled={isUseExistingCard}
                        />
                        {accountCityError && <div className="text-danger pt-1">! City is a required field</div>} 
                      </div>
                      <div className="col-lg-6 my-2">
                        <FloatSelectView
                          name = "country"
                          label = "Country"
                          list = {CountryList}
                          value = {country}
                          onInputChange = {e => onHandleAcountChange(e)}
                          isDisabled={isUseExistingCard}
                        />
                        {accountCountryError && <div className="text-danger pt-1">! Country is a required field</div>} 
                      </div>
                    </div>
                  </div>
                </div>
            </div>

            <div className="payment_right_view">
              <div className="row p-4 rounded-4 coupon w-100">
              <div className="d-flex justify-content-between">
                <Input 
                  type="text" 
                  id="coupon" 
                  placeHolder = "xxxx-xxxx" 
                  label = "coupon" 
                  name="coupon" 
                  classVal="w-100"
                  value={promoCode}
                  onInputChange = {e => SetPromoCode(e.target.value)}
                /> 
                <button className="btn btn-outline-warning ms-3"  onClick={getPromoCodeDetails}>Apply</button>
              </div>
              </div>

              {pageName !== "onBoarding" && <PaymentDetailsView />}
              {pageName === "onBoarding" && <SubscribePaymentDetailsView userDetails={userDetails} appliedPromoCode={appliedPromoCode}/>} 
            </div>
              {error.length !== 0 && <CustomizedSnackbars isShow={error.length !== 0} text={error} showAlert={(isHide) => setError("")} />}
              {success.length !== 0 && <CustomizedSnackbars isShow={success.length !== 0} text={success} showAlert={(isHide) => setSuccess("")}
                alertSeverity="success"
                alertStyle={{
                  backgroundColor: 'green !important'
                }}
              />}
            </div>
          </div>
        </div>
      </div>
    </div>      
  )
}

export default PaymentView;