import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {TicketActionMenuItems} from '../../utils/DefaultInitialValues';

const ActionMenu = (props) => {
  const {compoentName = "", onHandleMenuClick, isDisabled = false, actions = []} = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleMenuClick = (item = {}) => {
    onHandleMenuClick(item?.id);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="color-secondary-100 ax-button fs-5 fw-bold"
        disabled={isDisabled}
      >
        ...
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {actions && actions.length > 0 ?
          actions.map((item, index) => <MenuItem  onClick={() => handleMenuClick(item)} className="color-secondary-100">{item?.label}</MenuItem>)

          :
          TicketActionMenuItems.map((item, index) => <MenuItem disabled={isDisabled} onClick={() => handleMenuClick(item)} className="color-secondary-100">{item?.label}</MenuItem>)

        }
       
      </Menu>
    </div>
  );
}

export default ActionMenu;