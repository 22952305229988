import { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./dashbord_slider.css";
import { useMediaQuery } from "@uidotdev/usehooks";
import React from "react";
import { sliderItems } from "../../actions/Constants";
import SliderItem from "../../components/slider-item/SliderItem";
const SliderDashboard = ({ onClickHandler }) => {
  const isSmallDevice = useMediaQuery('(max-width:600px)')  

  const sliderRef = useRef(null);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
  };
  return (
    <div className="slider-parent-container">
      <div className="slider-parent-container-title">
        <span className="slider-parent-title">
          Meet the resources and experiences you need to fuel your growth
        </span>
      </div>
      <Slider ref={sliderRef} {...settings}>
        <SliderItem
          onChangeSliderIndex={() => {
            sliderRef.current?.slickNext();
          }}
          onClickHandler={onClickHandler}
          buttonText={sliderItems[0].buttonText}
          logo={
            isSmallDevice ? sliderItems[0].responsiveLogo : sliderItems[0].logo
          }
          description={sliderItems[0].description}
          key={0}
          title={sliderItems[0].title}
        />
        <SliderItem
          onChangeSliderIndex={() => {
            sliderRef.current?.slickNext();
          }}
          buttonText={sliderItems[1].buttonText}
          logo={isSmallDevice ? sliderItems[1].responsiveLogo : sliderItems[1].logo}
          description={sliderItems[1].description}
          title={sliderItems[1].title}
          onClickHandler={onClickHandler}
        />
        <SliderItem
          onChangeSliderIndex={() => {
            sliderRef.current?.slickGoTo(0);
          }}
          buttonText={sliderItems[2].buttonText}
          logo={sliderItems[2].logo}
          description={sliderItems[2].description}
          title={sliderItems[2].title}
          onClickHandler={onClickHandler}
        />
      </Slider>
    </div>
  );
};

export default SliderDashboard;
