import React from 'react'
import './arrow.css';

const Arrows = ({showLeftArrow = true, showRightArrow = true, showArrows = true, nextHandler , previousHandler}) => {
    return (
        showArrows && <div class="arrow-container">
            {showLeftArrow && <div class="arrow left" onClick={previousHandler}></div>}
            {showRightArrow && <div class="arrow right" onClick={nextHandler}></div>}
        </div>
    )
}

export default Arrows