import React, { useState, useEffect } from "react";
import CustomizedSnackbars from  '../CustomizedSnackbars';
import Modal from 'react-bootstrap/Modal';
import Loading from '../loading/Loading';
import {baseURL} from '../../actions/Constants';
import 'bootstrap/dist/css/bootstrap.min.css';
 
const DeleteDialogView = (props) => {
  const {serviceId = "", onHandleDeleteDialogClose, show = false} = props;

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isDeletable, setDeletable] = useState(false);

  useEffect(() => {
    isServiceDeletable();
  }, []);
  
  const handleClose = (isUpdate = false) => {
    onHandleDeleteDialogClose(isUpdate);
  }

  const isServiceDeletable = async () => {
    const URL=`${baseURL}/minded-services/isServiceDeletable/${serviceId}`;

    setLoading(true);
  
    return await fetch(URL)
    .then(response => {
      const resp = response;

      const {status = ""} = resp;
      setLoading(false);

      if(status === 200) {
        return response.json().then(res => { 
          if(res?.deleteable) {
            setDeletable(true);
          } else {
            setDeletable(true);
          }
        });
      } else {
        setDeletable(true);
      }
    })
    .catch((error) => {
      console.log('error isServiceDeletable', error);
      setLoading(false);
      setError(true);
    });
  };

  const handleDelete = () => {
    deleteService();
  };

  const deleteService = async () => {
    const URL=`${baseURL}/minded-services/deleteService/${serviceId}`;

    setLoading(true);
  
    return await fetch(URL)
    .then(response => {
      const resp = response;

      const {status = ""} = resp;

      setLoading(false);

      if(status === 200) {
        return response.json().then(res => { 
          handleClose(true);
        });
      } else {
        
      }
    })
    .catch((error) => {
      console.log('error deleteService', error);
      setLoading(false);
      setError(true);
    });
  };

  return (
    <>
      <Modal {...props} size="lg"  centered>
        <div className="modal-header">
          <h5 className="modal-title text-center w-100 color-primary-50 capitalize" id="exampleModalLabel">Confirmation</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
        </div>

        <Modal.Body>
          <>
          
          {isDeletable && <div className="text-center">
            <h5 className="heading-5 color-primary-50 my-3">Do you want to delete this Service?</h5>
            <div className="color-primary-50">Deleting the service also permanently deletes its schedules, with no way to undo the action.</div>
          </div>
          }

          {!isDeletable && <div className="text-center">
            <h5 className="heading-5 color-primary-50 my-3">This service can not deleted?</h5>
          </div>
          }

          </>
        </Modal.Body>
        
        <Modal.Footer className="py-3">
          <div className="w-100 d-flex justify-content-center">  
            <button 
              onClick={() => handleClose(false)} 
              className="w-25 tertiary_btn mx-3"
            >
            <lable style={{verticalAlign:"middle"}}>
              Close
            </lable>
          </button>

          {isDeletable && <button 
              onClick={handleDelete} 
              className="secondary_btn  mx-3"
            >
            <lable style={{verticalAlign:"middle"}}>
              Delete
            </lable>
          </button>
          }

          </div>
        </Modal.Footer>
      </Modal>
      {isLoading && <Loading />}
      {isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." />}
    </>
  )
}
export default DeleteDialogView;