import {useEffect, useState} from 'react';
import TimePickerView from '../TimePickerView';

const AddTimePickerView = (props) => {
	const {isDisabled = false, day = "", onTimeUpdate, addSchedule, removeSchedule, scheduleList = [], error = []} = props;
	
	const dayList = scheduleList.filter((item, inx) => item.day === day);
	
	const list = dayList.length > 0 ? dayList[0].dayWiseTimeList : [];

	const errorList = error && error.filter((item, inx) => item?.day === day);

	const dayWiseErrorArray = errorList && (errorList?.length > 0) && errorList[0] && errorList[0]?.dayWiseValidateList;

	return (
		<div className="w-100">
			<div className="w-100 d-flex justify-content-between align-items-center">
				<div className="capitalize fw-bold color-primary-50 body-B">
					{day.toUpperCase()}
				</div>
				<div>
					<button disabled = {isDisabled} className="capitalize tertiary_btn color-primary-50" onClick={() => addSchedule(day)}>+ Add more timing</button>
				</div>
			</div>

			{list.map((dayListItem, index) => <div className="container-fluid my-1">
				<div className="row my-3">
					<div className="col-lg-6 col-md-6">
						<div className="body-R color-primary-50">schedule {index+1}</div>
					</div>
					<div className="col-lg-6 col-md-6 d-flex justify-content-end">
						<button disabled = {isDisabled} className="ax-button" onClick = {() => removeSchedule(day, index)}>
							<i className='bx bx-x-circle text-danger'></i>
						</button>
					</div>
				</div>

				<div className="row my-3">
					<div className="col-6 col-md-6">
						<TimePickerView
							name="startTime" 
							value={dayListItem?.startTime} 
							onTimeChange = {(name, time) => onTimeUpdate(day, name, time, index)}
							isDisabled = {isDisabled}
						/>
						
						{dayWiseErrorArray[index] && dayWiseErrorArray[index]?.startTime && <div className="text-danger pt-1">! Start time is a required field</div>}
					
					</div>
					<div className="col-6 col-md-6">
						<TimePickerView 
							name="endTime" 
							value={dayListItem?.endTime} 
							onTimeChange = {(name, time) => onTimeUpdate(day, name, time, index)} 
							isDisabled = {isDisabled}
						/>
						{dayWiseErrorArray[index] && dayWiseErrorArray[index]?.endTime && <div className="text-danger pt-1">! End time is a required field</div>}
					</div>
				</div>
				{dayWiseErrorArray[index] && dayWiseErrorArray[index]?.range && <div className="text-danger pt-1">! Course time range is Invalid</div>}
			</div>)}
		</div>
	)
}

export default AddTimePickerView;