import React, {useState, useEffect} from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb } from "rsuite";

import HelmetDetails from "../../../../../components/HelmetDetails";
import Loading from '../../../../../components/loading/Loading';
import Table from '../../../../../components/table/Table';
import {getDateFromEpoc} from '../../../../../utils/util';
import {headerOption} from 	  '../../../../../utils/DefaultInitialValues';

import {baseURL} from '../../../../../actions/Constants';
import right_arrow from '../../../../../assets/thin arrow - right.png';

const AllPayments = () => {
	const location = useLocation();
	const [crumb, setCrumb] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [isError, setError] = useState(false);
	const [allPayments, setAllPayments] = useState([]);

	useEffect(() => {
        let locationVal = location?.pathname;
        const crumbs = location.pathname.split("/");
        createCrumbDetails(crumbs);
    }, [location]);

    const columns = [
    	{
		    name: "paymentId",
		    label: 'Payment Id',
		    
		    options: {
	       		filter: true,
       			sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	       			const filterPayment = allPayments.find(item => item?.paymentId === value);
	              	return (
	              		<>
			                <div>{value}</div>
		                </>
	              	);
	      		}
	        }
	    },
	    {
		    name: "merchantId",
		    label: 'Merchant Id',
		    
		    options: {
	       		filter: true,
       			sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	       			return (
	              		<>
			                <div>{value}</div>
		                </>
	              	);
	      		}
	        }
	    },
	    {
	      	name: "buyerName",
	      	label: 'Buyer Name',
	      	options: {
		       filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <div className="color-primary-50">
		                  {value}
		                </div>
	              	);
	      		}
		    }
	    },
	    {
	      	name: "email",
	      	label: 'Email',
	      	options: {
		       filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <div className="color-primary-50">
		                  {value}
		                 
		                </div>
	              	);
	      		}
		    }
	    },
	    {
	      	name: "type",
	      	label: 'Type',
	      	options: {
		       filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <div className="color-primary-50">
		                  {value}
		                </div>
	              	);
	      		}
		    }
	    },
	    {
	      	name: "purchaseDate",
	      	label: 'Date',
	      	options: {
	       		filter: true,
       			sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <span className="color-primary-50">
		                  	{getDateFromEpoc(value)}
		                </span>
	              	);
	      		}
	        }
	    },
	    {
	      name: "purchaseAmount",
	      label: 'Amount',
	      options: {
	        filter: true,
	        sort: true,
	        customBodyRender: (value, tableMeta, updateValue) => {
              	return (
	                <span className="color-primary-50">
	                  {value} EUR
	                </span>
              	);
      		}
	      }
	    },
	    {
	      	name: "catalystName",
	      	label: 'Catalyst Name',
	      	options: {
		       	filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <span className="color-primary-50">
		                  {value || "-"}
		                </span>
	              	);
	      		}
		    }
	    },
	    {
	      	name: "taxNo",
	      	label: 'Tax No.',
	      	options: {
		       filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	       			
	              	return (
		                <div className="color-primary-50">
		                  {value}
		                 
		                </div>
	              	);
	      		}
		    }
	    },

	    {
	      	name: "address",
	      	label: 'Address',
	      	options: {
		       filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	       		
	              	return (
		                <div className="color-primary-50">
		                  {value}
		                </div>
	              	);
	      		}
		    }
	    },
	    /*{
	      name: "purchaseStatus",
	      label: 'Status',
	      options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <div className="ax-button color-primary-50">
		                  {value || "-"}
		                </div>
	              	);
          		}
	      	},
	    }*/  
	];

	const getPurchaseDetails = async () => {
	   	setLoading(true);

	    const URL = `${baseURL}/minded-services/getPurchaseDetails/v2?purchaseStatus=active`;
	    
	    return await fetch(URL, headerOption)
	    .then(response => {
	      const resp = response;

	      const {status = ""} = resp;
	      
	      if(status === 200 || status === 201) {
	        return response.json().then(res => {
	        	console.log(res);
	        	const receivedData = res;
	        	//const sortedArr = res.sort((a, b) => b.purchaseDate - a.purchaseDate);
	        	const formattedData = [];

	        	receivedData.map((item, idx) => {
	        		const obj = {};
	        		const merchantId = item?.sibsCaptureRes?.merchant?.merchantTransactionId || item?.sibsCheckoutRes?.merchant?.merchantTransactionId ||"-";
	        		
	        		const paymentId = item?.paymentId;
	        		obj.paymentId = item?.paymentId;
	        		obj.merchantId = merchantId;
	        		const address = item?.address;

	       			obj.buyerName = item.userName;
	       			obj.email = item?.emailId || "-";
	       			obj.taxNo = item?.taxNo || "-";
			        obj.address =   `Street ${address?.street}, ${address?.apartment} - ${address?.city}, ${address?.state} - ${address?.pincode}, ${address?.country}`;  
	        		
			        const serviceName = item?.purchaseAdditionalDetails?.subscriptionName || item?.purchaseAdditionalDetails?.serviceName || "-";
	        		obj.type = `${item?.purchaseType} (${serviceName})`;	

	        		obj.catalystName = item?.catalystName;
	        		//obj.purchaseDate = getDateFromEpoc(item?.purchaseDate);
	        		obj.purchaseDate = item?.purchaseDate;
	        		obj.purchaseAmount = `${item?.purchaseAmount?.toFixed(2)}`;
	        		obj.purchaseStatus = item?.purchaseStatus?.toUpperCase();
	        		formattedData.push(obj);

	        	});
	        	  setLoading(false);
	          	//setAllPayments(res);
	          	setAllPayments(formattedData)
	        });
	        
	      } else {
	        //Error
	       setError(false);
	       setLoading(false);
	      }
	    })
	    .catch((error) => {
	      setLoading(false);
	      console.log('error getPurchaseDetails', error);
	      setError(true);  
	    });
  	}

    useEffect(() => {
    	getPurchaseDetails();
    }, []);

	const createCrumbDetails = (crumbs) => {
		const crumbArray = [];
		
		crumbs.map((crmb, index) => {
			const obj = {};

			switch(crmb) {
				case "":
					obj.path = "/";
					obj.label = "Home";
					crumbArray.push(obj);
					break;
				case "dashboard":
					obj.path = "/dashboard";
					obj.label = "My Dashboard";
					crumbArray.push(obj);
					break;
				case "payments":
					obj.path = "/dashboard/payments";
					obj.label = "All Payments";
					crumbArray.push(obj);
					break;
				default: break;
			}

			setCrumb(crumbArray);
		});
	}

	const options = {
	    selectableRows: false,
	    filter: false,
	    download: true,
	    print: true,
	    rowsPerPage: 100,
	    sortOrder: {
	      name: 'purchaseDate',
	      direction: 'desc'
	    },
  		onDownload: (buildHead, buildBody, columns, data) => {
		    // Format epoch to date for CSV download
		    const formattedData = data.map((row) => {
		      const newRow = { ...row.data };
		      //newRow[5] = new Date(newRow[5]).toLocaleDateString("en-US");
		      newRow[5] = getDateFromEpoc(newRow[5]);
		      return { data: Object.values(newRow) };
		    });

		    const fileName = `Minded_Invoice_${new Date().toISOString().slice(0, 19).replace(/[:T]/g, "-")}.csv`;

		    // Trigger CSV download
		    const csvContent = buildHead(columns) + buildBody(formattedData);
		    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		    const link = document.createElement("a");
		    link.href = URL.createObjectURL(blob);
		    link.setAttribute("download", fileName);
		    document.body.appendChild(link);
		    link.click();
		    document.body.removeChild(link);

		    return false; // Prevent default download behavior
  		},
  	};

	return (
		<div className="main-no-content-padding">
			<HelmetDetails title="All Payments - Admin Dashboard | Minded." />
			<Breadcrumb> 
                {crumb.map((item, index) => {
                	return (index !== crumb.length - 1) ? (
	                	<>
		                	<Link to={item.path}>
		                		<span className={`body-R color-secondary-100 px-2`}>{item.label}</span>
		                	</Link>
		                	<img src={right_arrow} style={{width: '12px', height: '12px'}}  alt = "" />
	                	</>
	                	) : (<span className={`body-R color-neutral-50 px-2`}>{item?.label}</span>)
                	}
                )}
	        </Breadcrumb>

	        <h1 className="heading-1 color-primary-50">All Payments</h1>
			
			<div className="my-5 payment_table">
			<Table headers={columns} rowData={allPayments} options = {options} />
			</div>
		  {isLoading && <Loading />}
		</div>
	)
}

export default AllPayments;

