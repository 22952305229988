import React, { useEffect, useState } from "react";
import "./promo-codes.css";
import CouponVector from "../../../../assets/promo-code-vector.svg";
import { Modal } from "react-bootstrap";
import PromoCodeModal from "./promo-codes/PromoCodeModal";
import { baseURL } from "../../../../actions/Constants";

const PromoCodes = () => {
  const [showModal,setShowModal] = useState(false)
  const [promoCodes, setPromoCodes] = useState([])
  const [showAll, setShowAll] = useState(false)
  useEffect(()=>{
    getPromoCodes()
  },[])

  const toggleModal = () =>{
    setShowModal(!showModal)
  }

  async function getPromoCodes () {
    const URL = `${baseURL}/minded-services/promoCodes`;
  
    try {
      const response = await fetch(URL, { method: 'GET' });
  
      if (!response.ok) {
        return;
      }
  
      const data = await response.json(); 
      setPromoCodes(data); 
    } catch (error) {
      return;
    }
  };
  
  const filterPromoCodes = (promoCodes) => {
    const active = promoCodes.filter(code => code.active);
    const expired = promoCodes.filter(code => !code.active);
 
    return { expired: expired.length, active: active.length };
  };

  const { expired, active } = filterPromoCodes(promoCodes);
  return (
    <>
      <div className="promo-code-container">
        <div className="promo-code-child-container">
          <div className="promo-code-header">
            <h2>Promocodes</h2>
            <button onClick={toggleModal}>ADD NEW</button>
          </div>
          <div className="promo-code-active-expired">
            <label>Active ({active})</label>
            <label>Expired ({expired})</label>
          </div>

          <div className="coupons-container">
            {!showAll && promoCodes.slice(0,3).map((coupon) => (
              <>
                <div className="coupon-item-container">
                  <div className="coupon-item">
                    <label>
                      {coupon.usages} of {coupon.usages} Used
                    </label>
                    <label>{coupon.code}</label>
                    <label>{coupon.codeName}</label>
                  </div>
                  <img src={CouponVector} />
                </div>
                <hr />
              </>))}
            {showAll && promoCodes.map((coupon) => (
              <>
                <div className="coupon-item-container">
                  <div className="coupon-item">
                    <label>
                      {coupon.usages} of {coupon.usages} Used
                    </label>
                    <label>{coupon.code}</label>
                    <label>{coupon.codeName}</label>
                  </div>
                  <img src={CouponVector} />
                </div>
                <hr />
              </>
            ))}
          </div>
          <div className="coupon-view-container">
            <button className="coupon-view-all" onClick={()=> setShowAll(!showAll)}>{showAll ? 'VIEW LESS' : 'VIEW ALL'}</button>
          </div>
        </div>
      </div>
      <Modal show={showModal} dialogClassName="modal-container">
        <Modal.Body>
          <PromoCodeModal toggleModal={toggleModal} getPromoCodes={getPromoCodes} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PromoCodes;
