import React, {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import CustomizedSnackbars from  '../../../../../components/CustomizedSnackbars';
import Input from '../../../../../components/input/Input';
import Loading from '../../../../../components/loading/Loading';

import brown_cancel from '../../../../../assets/brown_cancel.png';

import {InitialCategoryDetails, InitialCategoryErrors} from '../../../../../utils/DefaultInitialValues';
import { useCategories } from '../../../../../hooks/useCategories';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const UpdateCategory = (props) => {

	const {getAllCategories, addCategory, categoriesList, isAddedSuccesffully, isLoading, isUpdatedSuccessfully, updateCategory, isError, setError}  = useCategories()

	const [categoryName, setCategoryName] = useState('')
  	const {
	    details = InitialCategoryDetails,
	    isOpen = false, 
	    handleClose,
		setCategoriesList
  	} = props;

  	const isEdit = details !== null;

  	const [categoryError, setCategoryError] = useState(InitialCategoryErrors);
  	const [open, setOpen] = useState(false);

  	useEffect(() => {
  		setOpen(isOpen);
  	}, [isOpen]);

 

  	const validate = () => {
  		let isFormValid = true;

  		let categoryNameInvalid = false;
  		
  		if(categoryName === "") {
  			categoryNameInvalid = true;
  			isFormValid = false;
  		}

  		setCategoryError({...categoryError,
  			category_name_error: categoryNameInvalid
  		});

  		return isFormValid;
  	}

  	const onSubmit = () => {
  		const isValid = validate();
  		if(isValid) {
  			UpdateCategoryDetails();
  		}
  	}

  	const UpdateCategoryDetails =  () => {

		if(!isEdit){
			addCategory(categoryName)
		}else{
			updateCategory(details , categoryName)
		}
        
    }

	useEffect(()=>{
		getAllCategories();
	},[])

	useEffect(()=>{
		if((isAddedSuccesffully || isUpdatedSuccessfully) && categoriesList.length > 0){
			setCategoriesList(categoriesList)
			setTimeout(()=>{
				handleClose()
			},[1500])
		}
	},[isAddedSuccesffully, categoriesList, isUpdatedSuccessfully])

	useEffect(() => {
		if (isEdit) {
			setCategoryName(details?.name)
		}
	}, [isEdit, details])


  	const {
  		category_name_error = false
  	} = categoryError || {};

  	const title = isEdit ? "Update Category": "Create New Category";

	return (
		<>
	    <BootstrapDialog
	        onClose={handleClose}
	        aria-labelledby="customized-dialog-title"
	        open={open}
	    >

	    <DialogActions className="w-100 p-4 bg-white">
	        <div className="w-100 d-flex justify-content-between align-items-center">
	          <div>
	   
	          </div>

	          <h6 className="heading-6 color-primary-50">
	            {title}
	          </h6>

	          <div>
	            <button     
	              onClick={handleClose}
	              aria-label="close"
	              className="ax-button close_icon"
	            >
	              <img src={brown_cancel} className="close_icon" />
	            </button>
	          </div>
		    </div>
	    </DialogActions>

	        <DialogContent className="bg-white">
	      		<div className="container-fluid">
	      			<div className="row">
	      				<div className="col-lg-12">
	      					<div className="w-100 my-3">
					            <div className="w-100">
					              <Input
					                name="category_name"
					                label="Category name"
					                placeHolder="xxx" 
					                value={categoryName}
					                onInputChange = {e => setCategoryName(e.target.value)}
					              />
					            </div>
					            {category_name_error && <div className="text-danger">! Category name is a required field</div>}
					        </div>
					    </div>
		            </div>    
				</div>

	        </DialogContent>

	        <DialogActions className="bg-white">
	        	<div className="py-3 d-flex align-items-center justify-content-end w-100 px-3">
		        	<button onClick={handleClose} className="tertiary_btn capitalize color-primary-50 mx-2" >
		            	Cancel
		          	</button>

		          	<button autoFocus onClick={onSubmit} className="secondary_btn capitalize"  >
		           		{isEdit ? "Update" : "Add"}
		          	</button>
	            </div>
	        </DialogActions>

      	</BootstrapDialog>
      	{isLoading && <Loading />}
	    {isError.length > 0 && <CustomizedSnackbars isShow={isError} text={isError} showAlert={(isHide) => setError(false)} />}
	    {isAddedSuccesffully && <CustomizedSnackbars isShow={isAddedSuccesffully} alertSeverity='success' text="Successfully added new category" 
		 alertStyle={{
			backgroundColor: 'green !important'
		  }}
		/>}
	    {isUpdatedSuccessfully && <CustomizedSnackbars isShow={isUpdatedSuccessfully} alertSeverity='success' text="Successfully updated the category" 
		 alertStyle={{
			backgroundColor: 'green !important'
		  }}
		/>}
    </>
	)
}

export default UpdateCategory;