import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {getDateFromEpoc} from '../../../utils/util';
import ActionMenu from '../../../components/actionMenu/ActionMenu';
import {ConvertDaysArray, CalculateDuration, ConvertPrivateTimesArray} from '../../../utils/util';
import moment from 'moment';

import location_icon from '../../../assets/Location pin.png';

const ListView = (props) => {
	const navigate = useNavigate();
	const {
		indexval = 0, 
		onHandleServiceTitleClick, 
		onHandleEventScheduleClick, 
		onHandleMenuClick, 
		list = [], 
		isShow = false, 
		isDisabled = false
	} = props;

	const [isExpanded, setExpanded] = useState(false);

	const handleEventBookClick = (eachCls) => {
		onHandleEventScheduleClick(eachCls);
	}

	const onHandleMenuItemClick = (id = 0, service = {}) => {
		onHandleMenuClick(id, service);
	}

	const date_wise_array = list;

	return (
		<>
			{date_wise_array.length === 0 && <div className="h-100 my-3 no-class-container py-lg-3 py-2 px-3 text-center d-flex align-items-center justify-content-center">
				No Classes
			</div>}

			{date_wise_array.length !== 0 && date_wise_array.map((eachCls, indexVal) => {

			const {serviceName = "", serviceType = ""} = eachCls;
			const {serviceLocation = {}} = eachCls;
			const schedule_array_length =  eachCls.schedule ? eachCls.schedule.scheduleInfo.length : 0 ;

			const convertedDaysArray = ConvertDaysArray(eachCls, serviceType);

			const timeVal = ConvertPrivateTimesArray(eachCls, serviceType);

			return (
				<>
					<div className="desktop_view">
						<div className="d-flex align-items-center justify-content-between">
							<div>
								{serviceType !== "PRIVATE" && <div className="caption-R color-primary-50 py-1">{CalculateDuration(eachCls?.startTime,eachCls?.endTime)} - {convertedDaysArray.join(", ")} </div>}
								{serviceType === "PRIVATE" && <div className="caption-R color-primary-50 py-2">{timeVal} - {convertedDaysArray.join(", ")} </div>}
								
								<button 
									className="ax-button subtitle-B color-primary-50 text-left" 
									onClick={() => onHandleServiceTitleClick(eachCls)}
								>
									{eachCls?.serviceName}
								</button>
								{isShow && <div className="caption-R color-primary-50 py-1">{eachCls?.userDetails?.userName}</div>}
							</div>

							<div><ActionMenu isDisabled = {isDisabled} onHandleMenuClick = {(id) => onHandleMenuItemClick(id, eachCls)}  /></div>
						</div>
					</div>

					<div className="rounded-5 mobile_view m-lg-3">
						<div className="padding_Amit">
							<div className="d-flex justify-content-between align-items-center">
								<div>
									{serviceType !== "PRIVATE" && <div className="caption-R color-primary-50 py-2">{CalculateDuration(eachCls?.startTime,eachCls?.endTime)} - {convertedDaysArray.join(", ")} </div>}
									{serviceType === "PRIVATE" && <div className="caption-R color-primary-50 py-2">{timeVal} - {convertedDaysArray.join(", ")} </div>}
									
									<div className="subtitle-3 color-primary-50">
										<button className="ax-button subtitle-B color-primary-50 text-left" onClick={() => onHandleServiceTitleClick(eachCls)}>
											{eachCls?.serviceName}
										</button>
									</div>
									{isShow && <div className="caption-R color-primary-50 py-2">{eachCls?.userDetails?.userName}</div>}
								</div>
								<div><ActionMenu isDisabled = {isDisabled} onHandleMenuClick = {(id) => onHandleMenuItemClick(id, eachCls)} /></div>
							</div>
						</div>
					</div>

					{(indexVal !== (date_wise_array.length - 1)) && 
						<div className="">
							<hr />
						</div>
					}
				</>
			)
		})}
		</>
	)
}

export default ListView;