import React, {useEffect, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import AuthDialog from '../authenticate/AuthDialog';
import TextField from '@mui/material/TextField';
import {InitialUserDetails} from "../../utils/DefaultInitialValues";
import OnBoardingDialog from '../../pages/admin/onBoardingAdmin/OnBoardingDialog';

import './footer.css';
import footer_logo from '../../assets/footer_logo.svg';

const Footer = () => {
	const navigate = useNavigate();

	const [userDetails, setUserDetails] = useState(InitialUserDetails);
	const [isLoggedIn, setLoggedIn] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    
	const dispatch = useDispatch();

    const {
       details: loggedUser
    } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

	const handleEventBtnClick = () => {
    	navigate('/events');
    }

	const handleCatalystBtnClick = () => {
    	navigate('/catalysts');
    }

    const handleAboutBtnClick = () => {
    	navigate('/about');
    }

    const classes = {
    	inputLabel: {
    		borderColor: '#fff',
    		color: '#fff'
    	}
    }

    useEffect(() => {
    	setUserDetails(loggedUser);
    }, [loggedUser]);

    const isUserSignedIn = userDetails && userDetails?.userId && userDetails?.userId !== "";

	return(
		<footer className="footer">
			<div className="footer-sub-container">
				<div className="heading-4 footer_padding d-flex flex-column">
		        	<div className="d-flex align-items-center pb-3">
		        		<img src={footer_logo} alt="" className="w-50" />
		        	</div>
		        	<div className="footer_text_layout">
		        		<div className="pb-2">© 2025 minded.</div>
						<div>All Rights Reserved.</div>
					</div>	
		        </div>
		      
	        	<div className="footer_flex footer_padding">
		        	<div className="heading-7 capitalize headings color-primary-50">CONTACT</div>
		        	
		        	<ul className="none">
		        		<li><a href="https://www.instagram.com/theminded.club/" target="_blank">Instagram</a></li>
		        		<li><a href="https://www.facebook.com/theminded.club23" target="_blank">Facebook</a></li>
		        		<li><a className="mail" href="mailto:hello@theminded.club">hello@theminded.club</a></li>
		        		<li></li>
		        	</ul>
	        	</div>
		        
		        <div className="footer_flex footer_padding">
	        		<div className="heading-7 capitalize headings color-primary-50">MINDED</div>
		        	
		        	<ul className="none">
		        		<li><Link to="/catalysts">Catalysts</Link></li>
		        		<li><Link to="/events-classes">Events</Link></li>
		        		<li><Link to="/about">About</Link></li>
		        		<li><Link to="/contact">Contact</Link></li>
		        		
		        	</ul>
		        </div>

		         <div className="footer_flex footer_padding">
	        		<div className="heading-7 capitalize headings color-primary-50">Info</div>
					<ul className="none">
						<li><a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/themindedclub-prod.appspot.com/o/minded-files%2FMINDED%20_General%20Terms%20of%20Use_ENG%202025.pdf?alt=media&token=6e26581f-82d9-47c3-a66a-32241b01830b">
								Terms of Service
							</a>
						</li>
						<li><a href="https://firebasestorage.googleapis.com/v0/b/themindedclub-prod.appspot.com/o/minded-files%2FMINDED%20-%20Privacy%20Policy%20SERVICES%20PROVIDERS%20%26%20USERS%202025%20-%20ENG.pdf?alt=media&token=e0eb9bda-0427-4a74-bde1-83e876312979" target="_blank">Privacy Policy</a></li>
						<li><Link to="/contact">Complaint Book</Link></li>
					</ul>
		        	
		        </div>
		    </div>

	        <div className="d-flex flex-column justify-content-center green_bg community_padding">
	        	<div className="capitalize white_text caption-R">
	        		<div className="footer_white_text_font my-2">Join the Community</div>
	        		<div className="footer_white_text_font my-2">Stay Connected.</div>
	        	</div>

	        	<div className="capitalize subscribe_text white_text mt-2" aria-level="6">Subscribe to our newsletter</div>
	        	<div className="capitalize my-3">
	        		
	        		<TextField 
	        			id="standard-basic" 
	        			type="text"
	        			label="Name" 
	        			placeholder="Name"
	        			onChange={e => setName(e.target.value)}
	        			variant="standard" 
	        			InputLabelProps={{
			                classes: {
			                  root: classes.inputLabel,
			                  focused: classes.inputLabel,
			                }
              			}}
	        		/>
	        	</div>
	        	<div className="capitalize my-3">
	        		<TextField 
	        			id="standard-basic" 
	        			label="email"
	        			type="email"
	        			placeholder="Email"
	        			onChange={e => setEmail(e.target.value)} 
	        			variant="standard" 
	        			className="white_text"
	        			InputLabelProps={{
			                classes: {
			                  root: classes.inputLabel,
			                  focused: classes.inputLabel,
			                }
              			}}
              		/>
	        	</div>
	        	
	        	<div className="d-flex start-here-CTA my-2 w-100">
					<button className="primary_btn LINK capitalize">Subscribe</button>
				</div>
	        </div>

	        
	    </footer>
	)
}

export default Footer;

